// src/pages/hourly.tsx
'use client'

import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCampaignData } from "@/contexts/campaign-data";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, LineChart, Legend } from 'recharts';
import { useMetricFormatter, MetricKey, METRIC_CONFIGS, getMetricColor } from '@/lib/metrics';
import { useAccounts } from '@/contexts/account-context';
import { DEBUG } from '@/lib/constants';
import { LineChart as LineChartIcon, BarChart as BarChartIcon, AlertCircle } from 'lucide-react';

const SAMPLE_COLORS = {
  today: 'hsl(var(--muted))',
  yesterday: 'hsl(var(--muted-foreground))'
} as const

// Transform metrics consistently for both sample and real data
const transformMetrics = (row: any) => ({
  hour: Number(row.hour || row.Hour || 0),
  campaign: String(row.campaign || row.Campaign || ''),
  impr: Number(row.impr || row.Impressions || 0),
  clicks: Number(row.clicks || row.Clicks || 0),
  cost: Number(row.cost || row.Cost || 0),
  conv: Number(row.conv || row.Conversions || 0),
  value: Number(row.value || row.ConvValue || 0)
});

// Add helper for cumulative metrics
const CUMULATIVE_METRICS = ['Impressions', 'Clicks', 'Cost', 'Conversions', 'ConvValue'];

export default function Hourly() {
  const { data, loadingStatus, error } = useCampaignData();
  const { activeAccount } = useAccounts();
  const [timeRange, setTimeRange] = useState<'today' | 'yesterday'>('today');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(['Cost', 'ConvValue']);
  const [chartType, setChartType] = useState<'line' | 'bar'>('line');
  const [viewType, setViewType] = useState<'hourly' | 'cumulative'>('hourly');

  const accountCurrency = activeAccount?.currency || '$';
  const formatMetric = useMetricFormatter(accountCurrency);

  // Get raw data for the selected time range
  const currentData = useMemo(() => {
    const rawData = timeRange === 'today' ? data?.hourly : data?.hourly_yest;

    if (DEBUG) {
      console.log('Hourly: Raw dataset:', {
        timeRange,
        datasetLength: rawData?.length,
        sampleRow: rawData?.[0],
      });
    }

    if (!rawData?.length) return [];

    // Transform each row consistently
    return rawData.map(transformMetrics);
  }, [timeRange, data?.hourly, data?.hourly_yest]);

  // Get unique campaigns and their total cost
  const campaigns = useMemo(() => {
    if (!currentData?.length) return [];

    const campaignMap = new Map();
    currentData.forEach(row => {
      if (!campaignMap.has(row.campaign)) {
        campaignMap.set(row.campaign, {
          campaign: row.campaign,
          cost: 0,
          isSample: !activeAccount
        });
      }
      campaignMap.get(row.campaign).cost += row.cost;
    });

    return Array.from(campaignMap.values())
      .sort((a, b) => b.cost - a.cost);
  }, [currentData, activeAccount]);

  // Auto-select highest cost campaign when campaigns change
  useEffect(() => {
    if (campaigns.length > 0) {
      setSelectedCampaign(campaigns[0].campaign);
    }
  }, [campaigns, activeAccount]);

  // Get hourly data for selected campaign
  const hourlyData = useMemo(() => {
    if (!selectedCampaign || !currentData?.length) return [];

    // Get all rows for selected campaign
    const campaignRows = currentData.filter(row => row.campaign === selectedCampaign);

    if (DEBUG) {
      console.log('Campaign hourly data:', {
        campaign: selectedCampaign,
        rows: campaignRows.slice(0, 3)
      });
    }

    // Create array of 24 hours with actual data or zeros
    return Array.from({ length: 24 }, (_, hour) => {
      const row = campaignRows.find(r => r.hour === hour);
      return {
        hour,
        Cost: row?.cost || 0,
        Clicks: row?.clicks || 0,
        Impressions: row?.impr || 0,
        Conversions: row?.conv || 0,
        ConvValue: row?.value || 0,
        CTR: row?.impr ? (row.clicks / row.impr) * 100 : 0,
        CPC: row?.clicks ? row.cost / row.clicks : 0,
        CPA: row?.conv ? row.cost / row.conv : 0,
        ROAS: row?.cost ? row.value / row.cost : 0,
        AOV: row?.conv ? row.value / row.conv : 0
      };
    });
  }, [currentData, selectedCampaign]);

  // Calculate totals for metrics
  const totals = useMemo(() => {
    if (!hourlyData?.length) return {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
      CTR: 0,
      CPC: 0,
      CPA: 0,
      ROAS: 0,
      AOV: 0,
    };

    const sums = hourlyData.reduce((acc, row) => ({
      Impressions: acc.Impressions + row.Impressions,
      Clicks: acc.Clicks + row.Clicks,
      Cost: acc.Cost + row.Cost,
      Conversions: acc.Conversions + row.Conversions,
      ConvValue: acc.ConvValue + row.ConvValue,
    }), {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
    });

    return {
      ...sums,
      CTR: sums.Impressions > 0 ? (sums.Clicks / sums.Impressions) * 100 : 0,
      CPC: sums.Clicks > 0 ? sums.Cost / sums.Clicks : 0,
      CPA: sums.Conversions > 0 ? sums.Cost / sums.Conversions : 0,
      ROAS: sums.Cost > 0 ? sums.ConvValue / sums.Cost : 0,
      AOV: sums.Conversions > 0 ? sums.ConvValue / sums.Conversions : 0,
    };
  }, [hourlyData]);

  // Define available metrics
  const availableMetrics = useMemo(() =>
    Object.entries(METRIC_CONFIGS)
      .map(([key, config]) => ({
        key: key as keyof typeof METRIC_CONFIGS,
        ...config,
      }))
      .filter(metric => !['ImprShare', 'LostToBudget', 'LostToRank'].includes(metric.key as string)),
    []
  );

  const toggleMetric = (metricKey: MetricKey) => {
    setSelectedMetrics(current => {
      if (current.includes(metricKey)) {
        return current.filter(m => m !== metricKey);
      }
      if (current.length < 2) {
        return [...current, metricKey];
      }
      return [current[1], metricKey];
    });
  };

  // Calculate cumulative data at component level
  const cumulativeData = useMemo(() => {
    let cumulatives: { [key: string]: number } = {};
    return hourlyData.map(row => {
      const newRow = { ...row };
      CUMULATIVE_METRICS.forEach(metric => {
        cumulatives[metric] = (cumulatives[metric] || 0) + (row[metric] || 0);
        newRow[metric] = cumulatives[metric];
      });
      return newRow;
    });
  }, [hourlyData]);

  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return <div className="flex items-center justify-center h-96">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-96 text-red-500">Error: {error}</div>;
  }

  if (!currentData?.length) {
    return <div className="flex items-center justify-center h-96">No data available</div>;
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <Select value={selectedCampaign} onValueChange={setSelectedCampaign}>
          <SelectTrigger className="w-[300px] md:w-[400px] lg:w-[500px]">
            <SelectValue>
              {selectedCampaign ? (
                <div className="flex items-center justify-between w-full">
                  <span className="truncate max-w-[200px]">
                    {!activeAccount && '(Sample) '}{selectedCampaign}
                  </span>
                  <span className="text-sm font-medium text-muted-foreground ml-2">
                    {formatMetric('Cost', campaigns.find(c => c.campaign === selectedCampaign)?.cost || 0)}
                  </span>
                </div>
              ) : (
                "Select a campaign"
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {campaigns.map(({ campaign, cost, isSample }) => (
              <SelectItem
                key={campaign}
                value={campaign}
                className="flex items-center justify-between py-2 pr-2"
              >
                <span className="truncate mr-4">
                  {isSample && '(Sample) '}{campaign}
                </span>
                <span className="text-sm font-medium text-muted-foreground">
                  {formatMetric('Cost', cost)}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Tabs value={timeRange} onValueChange={(value) => setTimeRange(value as 'today' | 'yesterday')}>
          <TabsList className="bg-transparent gap-2">
            <TabsTrigger value="today" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
              Today
            </TabsTrigger>
            <TabsTrigger value="yesterday" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
              Yesterday
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {/* Metric Cards */}
      <div className="grid grid-cols-10 gap-2">
        {availableMetrics.map(metric => (
          <Card
            key={metric.key}
            onClick={() => toggleMetric(metric.key)}
            className={`cursor-pointer transition-colors hover:bg-accent ${selectedMetrics.includes(metric.key) ? 'ring-2 ring-primary' : ''
              }`}
          >
            <CardHeader className="pb-1 pt-2 px-3 space-y-0">
              <CardTitle className="text-xs font-medium">
                {!activeAccount && '(Sample) '}{metric.label}
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-2 px-3">
              <div className="text-lg font-bold">
                {formatMetric(metric.key, totals[metric.key] || 0)}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Main Chart Card */}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <Tabs
              value={viewType}
              onValueChange={(value) => setViewType(value as 'hourly' | 'cumulative')}
              className="w-[400px]"
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="hourly">Hourly Performance</TabsTrigger>
                <TabsTrigger value="cumulative">Cumulative Analysis</TabsTrigger>
              </TabsList>
            </Tabs>
            <Tabs value={chartType} onValueChange={(value) => setChartType(value as 'line' | 'bar')}>
              <TabsList className="grid w-fit grid-cols-2">
                <TabsTrigger value="line" className="px-4">
                  <LineChartIcon className="h-4 w-4 mr-2" />
                  Line
                </TabsTrigger>
                <TabsTrigger value="bar" className="px-4">
                  <BarChartIcon className="h-4 w-4 mr-2" />
                  Bar
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardHeader>
        <CardContent>
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              {viewType === 'hourly' ? (
                chartType === 'line' ? (
                  <LineChart data={hourlyData}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="hour"
                      tickFormatter={(hour) => `${hour}:00`}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey];
                      if (!metric) return null;

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                          />
                          <Line
                            type="monotone"
                            dataKey={metricKey}
                            stroke={!activeAccount ? "#94a3b8" : getMetricColor(index)}
                            yAxisId={index}
                            name={METRIC_CONFIGS[metricKey].label}
                            dot={false}
                            strokeWidth={2}
                          />
                        </React.Fragment>
                      );
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        );
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ];
                      }}
                      labelFormatter={(hour) => `${hour}:00`}
                    />
                    <Legend />
                  </LineChart>
                ) : (
                  <BarChart data={hourlyData}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="hour"
                      tickFormatter={(hour) => `${hour}:00`}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey];
                      if (!metric) return null;

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                          />
                          <Bar
                            dataKey={metricKey}
                            fill={!activeAccount ? "#94a3b8" : getMetricColor(index)}
                            yAxisId={index}
                            name={METRIC_CONFIGS[metricKey].label}
                            radius={[4, 4, 0, 0]}
                          />
                        </React.Fragment>
                      );
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        );
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ];
                      }}
                      labelFormatter={(hour) => `${hour}:00`}
                    />
                    <Legend />
                  </BarChart>
                )
              ) : (
                selectedMetrics.some(metric => CUMULATIVE_METRICS.includes(metric)) ? (
                  chartType === 'line' ? (
                    <LineChart data={cumulativeData}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis
                        dataKey="hour"
                        tickFormatter={(hour) => `${hour}:00`}
                      />
                      {selectedMetrics
                        .filter(metric => CUMULATIVE_METRICS.includes(metric))
                        .map((metricKey, index) => {
                          const metric = METRIC_CONFIGS[metricKey];
                          if (!metric) return null;

                          return (
                            <React.Fragment key={metricKey}>
                              <YAxis
                                yAxisId={index}
                                orientation={index === 0 ? "left" : "right"}
                                tickFormatter={(value) => formatMetric(metricKey, value)}
                              />
                              <Line
                                type="monotone"
                                dataKey={metricKey}
                                stroke={!activeAccount ? "#94a3b8" : getMetricColor(index)}
                                yAxisId={index}
                                name={METRIC_CONFIGS[metricKey].label}
                                dot={false}
                                strokeWidth={2}
                              />
                            </React.Fragment>
                          );
                        })}
                      <Tooltip
                        formatter={(value: number, name: string) => {
                          const metricKey = selectedMetrics.find(key =>
                            METRIC_CONFIGS[key].label === name
                          );
                          return [
                            metricKey ? formatMetric(metricKey, value) : value,
                            name
                          ];
                        }}
                        labelFormatter={(hour) => `${hour}:00`}
                      />
                      <Legend />
                    </LineChart>
                  ) : (
                    <BarChart data={cumulativeData}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis
                        dataKey="hour"
                        tickFormatter={(hour) => `${hour}:00`}
                      />
                      {selectedMetrics
                        .filter(metric => CUMULATIVE_METRICS.includes(metric))
                        .map((metricKey, index) => {
                          const metric = METRIC_CONFIGS[metricKey];
                          if (!metric) return null;

                          return (
                            <React.Fragment key={metricKey}>
                              <YAxis
                                yAxisId={index}
                                orientation={index === 0 ? "left" : "right"}
                                tickFormatter={(value) => formatMetric(metricKey, value)}
                              />
                              <Bar
                                dataKey={metricKey}
                                fill={!activeAccount ? "#94a3b8" : getMetricColor(index)}
                                yAxisId={index}
                                name={METRIC_CONFIGS[metricKey].label}
                                radius={[4, 4, 0, 0]}
                              />
                            </React.Fragment>
                          );
                        })}
                      <Tooltip
                        formatter={(value: number, name: string) => {
                          const metricKey = selectedMetrics.find(key =>
                            METRIC_CONFIGS[key].label === name
                          );
                          return [
                            metricKey ? formatMetric(metricKey, value) : value,
                            name
                          ];
                        }}
                        labelFormatter={(hour) => `${hour}:00`}
                      />
                      <Legend />
                    </BarChart>
                  )
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-center">
                    <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
                    <h3 className="text-lg font-medium mb-2">Cumulative Analysis</h3>
                    <p className="text-muted-foreground max-w-md">
                      Cumulative analysis is only available for base metrics (Impressions, Clicks, Cost, Conversions, Value).
                      Select a base metric to view cumulative performance.
                    </p>
                  </div>
                )
              )}
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}