// src/components/layouts/root-layout.tsx
import { useLocation } from 'react-router-dom';
import { Navbar } from '@/components/navigation/navbar';
import { useUser } from '@clerk/clerk-react';
import { Progress } from '@/components/ui/progress';
import { SampleDataBanner } from '@/components/sample-data-banner';
import ErrorBoundary from '@/components/error-boundary';
import { ToastProvider, ToastViewport } from '@/components/ui/toast';
import { useEffect } from 'react';
import { useCampaignData } from '@/contexts/campaign-data';
import { useAccounts } from '@/contexts/account-context';

const DEBUG = process.env.NODE_ENV === 'development'

// Pages that should show sample data banner
const DATA_PAGES = ['/analysis', '/trends', '/curve', '/hourly']

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const { user, isLoaded } = useUser();
  const { useSampleData } = useCampaignData();
  const { activeAccount } = useAccounts();
  const location = useLocation();

  useEffect(() => {
    console.log('RootLayout: Auth State', {
      isLoaded,
      hasUser: !!user,
      path: location.pathname,
      useSampleData,
      isSampleAccount: activeAccount?.isSampleAccount
    })
  }, [isLoaded, user, location, useSampleData, activeAccount])

  // Only show banner on data pages and when using sample account
  const showSampleBanner = DATA_PAGES.includes(location.pathname) && activeAccount?.isSampleAccount

  return (
    <ErrorBoundary>
      <ToastProvider>
        <div className="relative min-h-screen bg-background font-sans antialiased">
          <div className="relative flex min-h-screen flex-col">
            <Navbar />
            <main className="flex-1 container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-8">
              {showSampleBanner && <SampleDataBanner />}
              {children}
            </main>
          </div>
        </div>
        <ToastViewport />
      </ToastProvider>
    </ErrorBoundary>
  )
}