// src/contexts/account-context.tsx
import { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react'
import { useUser } from '@clerk/clerk-react'
import { Account } from '@/types/metrics'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { AlertCircle } from 'lucide-react'

interface AccountContextType {
  accounts: Account[]
  activeAccount: Account | null
  activeDexieId: string | null
  isLoading: boolean
  error: string | null
  setAccounts: (newAccounts: Account[]) => void
  setActiveAccount: (account: Account | null) => void
  switchAccount: (dexieId: string) => void
  addAccount: (account: Account) => Promise<Account>
  updateAccount: (account: Account, optimistic?: boolean) => Promise<void>
  deleteAccount: (dexieId: string) => Promise<void>
  SampleDataBanner: () => JSX.Element | null
}

const AccountContext = createContext<AccountContextType | null>(null)

function SampleDataBanner() {
  const { activeAccount } = useAccounts()
  
  if (!activeAccount?.isSampleAccount) return null

  return (
    <Alert variant="warning" className="mb-4">
      <AlertCircle className="h-4 w-4" />
      <AlertDescription>
        You are viewing sample data. To view your own data, please add your account in the settings page.
      </AlertDescription>
    </Alert>
  )
}

export function AccountProvider({ children }: { children: React.ReactNode }) {
  const [accounts, setAccounts] = useState<Account[]>([])
  const [activeAccount, setActiveAccount] = useState<Account | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { isSignedIn } = useUser()

  // Load accounts from storage
  useEffect(() => {
    const loadAccounts = () => {
      try {
        // Only load accounts if user is signed in
        if (!isSignedIn) {
          setAccounts([])
          setActiveAccount(null)
          setIsLoading(false)
          return
        }

        const storedAccounts = localStorage.getItem('accounts')
        const storedActiveId = localStorage.getItem('activeAccountId')
        
        if (storedAccounts) {
          const parsedAccounts = JSON.parse(storedAccounts) as Account[]
          setAccounts(parsedAccounts)
          
          if (storedActiveId) {
            const active = parsedAccounts.find(a => a.dexieId === storedActiveId)
            setActiveAccount(active || null)
          }
        }
      } catch (err) {
        console.error('Error loading accounts:', err)
        setError('Failed to load accounts')
      } finally {
        setIsLoading(false)
      }
    }

    loadAccounts()
  }, [isSignedIn])

  // Save accounts to storage
  useEffect(() => {
    if (isSignedIn && accounts.length > 0) {
      localStorage.setItem('accounts', JSON.stringify(accounts))
    }
  }, [accounts, isSignedIn])

  // Save active account to storage
  useEffect(() => {
    if (isSignedIn && activeAccount) {
      localStorage.setItem('activeAccountId', activeAccount.dexieId)
    }
  }, [activeAccount, isSignedIn])

  // Account management methods
  const addAccount = useCallback(async (account: Account): Promise<Account> => {
    if (!isSignedIn) throw new Error('Not signed in')
    setAccounts(prev => [...prev, account])
    if (!activeAccount) {
      setActiveAccount(account)
    }
    return account
  }, [isSignedIn, activeAccount])

  const updateAccount = useCallback(async (updatedAccount: Account, optimistic = false): Promise<void> => {
    if (!isSignedIn) throw new Error('Not signed in')
    setAccounts(prev => prev.map(account => 
      account.dexieId === updatedAccount.dexieId ? updatedAccount : account
    ))
    if (activeAccount?.dexieId === updatedAccount.dexieId) {
      setActiveAccount(updatedAccount)
    }
  }, [isSignedIn, activeAccount])

  const deleteAccount = useCallback(async (dexieId: string): Promise<void> => {
    if (!isSignedIn) throw new Error('Not signed in')
    setAccounts(prev => prev.filter(account => account.dexieId !== dexieId))
    if (activeAccount?.dexieId === dexieId) {
      const remainingAccounts = accounts.filter(account => account.dexieId !== dexieId)
      setActiveAccount(remainingAccounts[0] || null)
    }
  }, [isSignedIn, activeAccount, accounts])

  const switchAccount = useCallback((dexieId: string) => {
    const account = accounts.find(a => a.dexieId === dexieId)
    setActiveAccount(account || null)
  }, [accounts])

  const value = useMemo(() => ({
    accounts: isSignedIn ? accounts : [],
    activeAccount: isSignedIn ? activeAccount : null,
    activeDexieId: isSignedIn ? activeAccount?.dexieId || null : null,
    isLoading,
    error,
    setAccounts: (newAccounts: Account[]) => {
      if (isSignedIn) {
        setAccounts(newAccounts)
      }
    },
    setActiveAccount: (account: Account | null) => {
      if (isSignedIn) {
        setActiveAccount(account)
      }
    },
    switchAccount,
    addAccount,
    updateAccount,
    deleteAccount,
    SampleDataBanner
  }), [accounts, activeAccount, isLoading, error, isSignedIn, addAccount, updateAccount, deleteAccount, switchAccount])

  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccounts = () => {
  const context = useContext(AccountContext)
  if (!context) {
    throw new Error('useAccounts must be used within AccountProvider')
  }
  return context
}