// src/components/budget-optimization/use-campaign-projections.ts

import { useState, useMemo, useEffect } from 'react';
import { OptimizationMode, RowAdjustment } from './types';
import {
    calculateProfit,
    generateCurvePoints,
    findOptimalZone
} from '@/lib/profit-calculations';
import type { CampaignMetrics, ResponseModel, ProfitCalcMetrics } from '@/types/metrics';
import type { OptimalZone, CurvePoint } from '@/types/profit';
import type { BusinessMode, CampaignWithShare } from '@/types/metrics';

const DEBUG = process.env.NODE_ENV === 'development';

const OPTIMIZATION_ADJUSTMENTS = {
    none: {
        increase: 0,
        decrease: 0
    },
    conservative: {
        increase: 15,   // Maximum +15% increase
        decrease: -10   // Maximum -10% decrease
    },
    balanced: {
        increase: 25,   // Maximum +25% increase
        decrease: -20   // Maximum -20% decrease
    },
    aggressive: {
        increase: 40,   // Maximum +40% increase
        decrease: -30   // Maximum -30% decrease
    }
};

// Helper function to enforce mode limits
function enforceModeLimits(adjustment: number, mode: OptimizationMode): number {
    if (mode === 'none') return 0;
    const limits = OPTIMIZATION_ADJUSTMENTS[mode];
    
    // For decreases (negative adjustments)
    if (adjustment < 0) {
        return Math.max(limits.decrease, adjustment);
    }
    // For increases (positive adjustments)
    return Math.min(limits.increase, adjustment);
}

// Helper function to calculate adjustment based on optimization mode and optimal zone
function calculateAdjustment(
    campaign: CampaignWithShare,
    mode: OptimizationMode,
    businessMode: BusinessMode,
    breakEvenCpa: number
): number {
    if (mode === 'none') return 0;
    
    // Generate curve points to find optimal zone
    const curvePoints = generateCurvePoints({
        currentCost: campaign.Cost,
        currentConv: campaign.Conversions,
        currentValue: campaign.ConvValue,
        currentRevenue: campaign.ConvValue,
        cost: campaign.Cost,
        conv: campaign.Conversions,
        value: campaign.ConvValue,
        clicks: campaign.Clicks,
        impr: campaign.Impressions,
        responseModel: 'diminishing',
        businessMode,
        cogsAmount: 0, // We'll handle COGS in profit calculation
        breakEvenCpa,
        impressionShare: campaign.ImprShare || 1
    });

    const optimalZone = findOptimalZone(curvePoints, campaign.Cost);
    const adjustments = OPTIMIZATION_ADJUSTMENTS[mode];

    // If we're below optimal zone, increase (but cap at mode's max)
    if (campaign.Cost < optimalZone.start) {
        const targetIncrease = ((optimalZone.start - campaign.Cost) / campaign.Cost) * 100;
        return Math.min(adjustments.increase, Math.round(targetIncrease));
    }
    
    // If we're above optimal zone, decrease (but cap at mode's max)
    if (campaign.Cost > optimalZone.end) {
        const targetDecrease = ((campaign.Cost - optimalZone.end) / campaign.Cost) * 100;
        return Math.min(adjustments.decrease, -Math.round(targetDecrease));
    }

    // Within optimal zone - no change needed
    return 0;
}

export function useCampaignProjections(
    campaigns: CampaignWithShare[],
    cogsPercentage: number,
    optimizationMode: OptimizationMode,
    includeFilter: string,
    excludeFilter: string,
    accountCurrency: string,
    responseModel: ResponseModel,
    businessMode: BusinessMode,
    breakEvenCpa: number
) {
    const [rowAdjustments, setRowAdjustments] = useState<Record<string, RowAdjustment>>({});
    const [lastMode, setLastMode] = useState<OptimizationMode>(optimizationMode);
    const [lastCampaignSet, setLastCampaignSet] = useState<Set<string>>(new Set());

    // Clear filters when account changes
    useEffect(() => {
        const currentCampaignSet = new Set(campaigns.map(c => c.Campaign));
        const hasCommonCampaigns = Array.from(lastCampaignSet).some(campaign => currentCampaignSet.has(campaign));
        
        // If there are no common campaigns between the last set and current set, it's likely an account switch
        if (lastCampaignSet.size > 0 && currentCampaignSet.size > 0 && !hasCommonCampaigns) {
            // Use callback to clear filters
            if (typeof window !== 'undefined') {
                window.dispatchEvent(new CustomEvent('clearBudgetFilters'));
            }
        }
        
        setLastCampaignSet(currentCampaignSet);
    }, [campaigns]);

    // Filter campaigns based on include/exclude filters
    const filteredCampaigns = useMemo(() => {
        return campaigns.filter(campaign => {
            const name = campaign.Campaign?.toLowerCase() || '';
            const include = includeFilter?.toLowerCase() || '';
            const exclude = excludeFilter?.toLowerCase() || '';

            if (include && !name.includes(include)) return false;
            if (exclude && name.includes(exclude)) return false;
            return true;
        });
    }, [campaigns, includeFilter, excludeFilter]);

    const campaignProjections = useMemo(() => {
        return filteredCampaigns.map(campaign => {
            // Get adjustment from slider or calculate from optimization mode
            const currentAdjustment = rowAdjustments[campaign.Campaign]?.adjustment ?? 
                calculateAdjustment(campaign, optimizationMode, businessMode, breakEvenCpa);

            const projectedCost = campaign.Cost * (1 + currentAdjustment / 100);

            // Calculate current profit
            const currentProfit = businessMode === 'leadgen'
                ? (campaign.Conversions * breakEvenCpa) - campaign.Cost
                : campaign.ConvValue - campaign.Cost - (campaign.ConvValue * cogsPercentage / 100);

            // Generate curve points for optimal zone calculation
            const curvePoints = generateCurvePoints({
                currentCost: campaign.Cost,
                currentConv: campaign.Conversions,
                currentValue: campaign.ConvValue,
                currentRevenue: campaign.ConvValue,
                cost: campaign.Cost,
                conv: campaign.Conversions,
                value: campaign.ConvValue,
                clicks: campaign.Clicks,
                impr: campaign.Impressions,
                responseModel: 'diminishing',
                businessMode,
                cogsAmount: cogsPercentage,
                breakEvenCpa,
                impressionShare: campaign.ImprShare || 1
            });

            // Find optimal zone using curve points
            const optimalZone = findOptimalZone(curvePoints, campaign.Cost);

            // Calculate projected metrics based on response model
            let projectedConv, projectedValue, projectedProfit;
            if (responseModel === 'linear') {
                const changeRatio = projectedCost / campaign.Cost;
                projectedConv = campaign.Conversions * changeRatio;
                projectedValue = campaign.ConvValue * changeRatio;
                
                if (businessMode === 'leadgen') {
                    projectedProfit = (projectedConv * breakEvenCpa) - projectedCost;
                } else {
                    // For ecommerce in linear mode:
                    // If cost changes by X%, revenue changes by X%, COGS changes by X%
                    // Therefore profit also changes by X%
                    projectedProfit = projectedValue - projectedCost - (projectedValue * cogsPercentage / 100);
                }
            } else {
                // For diminishing returns, use power function
                const changeRatio = projectedCost / campaign.Cost;
                const POWER_FACTOR = 0.4; // Same as in curve page
                projectedConv = campaign.Conversions * Math.pow(changeRatio, POWER_FACTOR);
                projectedValue = campaign.ConvValue * Math.pow(changeRatio, POWER_FACTOR);
                
                if (businessMode === 'leadgen') {
                    projectedProfit = (projectedConv * breakEvenCpa) - projectedCost;
                } else {
                    projectedProfit = projectedValue - projectedCost - (projectedValue * cogsPercentage / 100);
                }
            }

            // Simple profit change calculation
            const profitChange = projectedProfit - currentProfit;

            // Determine recommendation based on profit curve data
            let recommendation = 'No Change';
            let changeReason = '';

            if ((campaign.ImprShare || 0) >= 0.9) {
                recommendation = 'No Change';
                changeReason = `High impression share (${Math.round((campaign.ImprShare || 0) * 100)}%)`;
            } else if (currentProfit < 0) {
                recommendation = 'Decrease Cost';
                changeReason = `Unprofitable (${accountCurrency}${Math.round(currentProfit)} profit)`;
            } else {
                // Check optimal range first
                if (campaign.Cost >= optimalZone.start && campaign.Cost <= optimalZone.end) {
                    recommendation = 'No Change';
                    changeReason = `Within optimal range (${accountCurrency}${Math.round(optimalZone.start)}-${accountCurrency}${Math.round(optimalZone.end)})`;
                    // Add impression share info as additional context
                    if (campaign.LostToBudget > 0.15) {
                        changeReason += `. Note: ${Math.round(campaign.LostToBudget * 100)}% impression share lost to budget`;
                    }
                } else if (campaign.Cost < optimalZone.start) {
                    recommendation = 'Increase Cost';
                    if (campaign.LostToBudget > 0.1) {
                        changeReason = `Below optimal range (${accountCurrency}${Math.round(optimalZone.start)}) and ${Math.round(campaign.LostToBudget * 100)}% impression share lost to budget`;
                    } else {
                        changeReason = `Below optimal range (${accountCurrency}${Math.round(optimalZone.start)})`;
                    }
                } else {
                    recommendation = 'Decrease Cost';
                    changeReason = `Above optimal range (${accountCurrency}${Math.round(optimalZone.end)})`;
                }
            }

            return {
                Campaign: campaign.Campaign,
                currentCost: campaign.Cost,
                currentProfit,
                currentConversions: campaign.Conversions,
                currentClicks: campaign.Clicks,
                projectedCost,
                costChange: projectedCost - campaign.Cost,
                projectedProfit,
                projectedConversions: projectedConv,
                projectedClicks: campaign.Clicks * (projectedCost / campaign.Cost),
                projectedImpressions: campaign.Impressions * (projectedCost / campaign.Cost),
                projectedRevenue: projectedValue,
                percentChange: currentAdjustment,
                profitChange,
                currentIS: (campaign.ImprShare || 0) * 100,
                projectedIS: Math.min(100, (campaign.ImprShare || 0) * (1 + currentAdjustment / 100)),
                changeReason,
                optimalMin: optimalZone.start,
                optimalMax: optimalZone.end,
                isHighIS: (campaign.ImprShare || 0) >= 0.9,
                recommendation
            };
        });
    }, [
        filteredCampaigns,
        optimizationMode,
        businessMode,
        cogsPercentage,
        breakEvenCpa,
        responseModel,
        accountCurrency,
        rowAdjustments
    ]);

    // Handle optimization mode changes
    useEffect(() => {
        if (optimizationMode === lastMode) return;
        setLastMode(optimizationMode);

        const newAdjustments: Record<string, RowAdjustment> = {};

        campaignProjections.forEach(projection => {
            const { increase, decrease } = OPTIMIZATION_ADJUSTMENTS[optimizationMode];
            let adjustment = 0;
            let changeReason = '';

            // Skip high impression share campaigns
            if (projection.isHighIS) {
                adjustment = 0;
                changeReason = 'High impression share';
            } 
            // If within optimal range, force no change regardless of impression share
            else if (projection.currentCost >= projection.optimalMin && 
                     projection.currentCost <= projection.optimalMax) {
                adjustment = 0;
                changeReason = projection.changeReason;
            }
            // If campaign is unprofitable or recommendation is to decrease, force a decrease
            else if (projection.recommendation === 'Decrease Cost') {
                if (projection.currentCost > projection.optimalMax) {
                    const targetDecrease = ((projection.currentCost - projection.optimalMax) / projection.currentCost) * 100;
                    adjustment = -Math.round(targetDecrease);
                } else {
                    adjustment = decrease;
                }
                adjustment = enforceModeLimits(adjustment, optimizationMode);
                changeReason = projection.changeReason;
            }
            // Only consider increases if we're not being told to decrease and not in optimal range
            else if (projection.currentCost < projection.optimalMin) {
                const targetIncrease = ((projection.optimalMin - projection.currentCost) / projection.currentCost) * 100;
                adjustment = Math.round(targetIncrease);
                adjustment = enforceModeLimits(adjustment, optimizationMode);
                changeReason = projection.changeReason;
            }

            if (adjustment !== 0) {
                newAdjustments[projection.Campaign] = {
                    campaignName: projection.Campaign,
                    adjustment
                };
            }
        });

        setRowAdjustments(newAdjustments);
    }, [optimizationMode, campaignProjections, lastMode, accountCurrency]);

    // Calculate projected metrics totals
    const projectedMetrics = useMemo(() => {
        // Calculate current totals
        const current = filteredCampaigns.reduce((acc, campaign) => ({
            cost: acc.cost + campaign.Cost,
            revenue: acc.revenue + campaign.ConvValue,
            profit: acc.profit + calculateProfit(
                { 
                    cost: campaign.Cost,
                    conv: campaign.Conversions,
                    value: campaign.ConvValue,
                    clicks: campaign.Clicks,
                    impr: campaign.Impressions
                },
                { businessMode, cogsAmount: cogsPercentage, breakEvenCpa }
            ),
            conversions: acc.conversions + campaign.Conversions,
            clicks: acc.clicks + campaign.Clicks,
            impressions: acc.impressions + campaign.Impressions
        }), {
            cost: 0,
            revenue: 0,
            profit: 0,
            conversions: 0,
            clicks: 0,
            impressions: 0
        });

        // Always calculate projected totals from campaignProjections
        const projected = campaignProjections.reduce((acc, projection) => ({
            cost: acc.cost + projection.projectedCost,
            revenue: acc.revenue + projection.projectedRevenue,
            profit: acc.profit + projection.projectedProfit,
            conversions: acc.conversions + projection.projectedConversions,
            clicks: acc.clicks + projection.projectedClicks,
            impressions: acc.impressions + projection.projectedImpressions
        }), {
            cost: 0,
            revenue: 0,
            profit: 0,
            conversions: 0,
            clicks: 0,
            impressions: 0
        });

        return { current, projected };
    }, [filteredCampaigns, campaignProjections, optimizationMode, cogsPercentage, businessMode, breakEvenCpa]);

    return {
        campaignProjections,
        projectedMetrics,
        rowAdjustments,
        setRowAdjustments,
        filteredCampaigns
    };
}