import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { DATA_SOURCES } from "@/lib/constants"
import { useCampaignData } from "@/contexts/campaign-data"
import { CheckCircle2, AlertCircle, Loader2 } from "lucide-react"

interface DataSourceStatusProps {
  dexieId: string
  isRefreshing?: boolean
}

export function DataSourceStatus({ dexieId, isRefreshing }: DataSourceStatusProps) {
  const { accountData } = useCampaignData()
  const data = accountData[dexieId]
  
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Source</TableHead>
          <TableHead>Records</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(DATA_SOURCES).map(([tab, source]) => {
          const records = data?.[tab]?.length || 0

          return (
            <TableRow key={tab}>
              <TableCell>
                <div className="flex flex-col">
                  <span>{source.title}</span>
                  <span className="text-xs text-muted-foreground">{source.description}</span>
                </div>
              </TableCell>
              <TableCell>{records.toLocaleString()}</TableCell>
              <TableCell>
                {isRefreshing ? (
                  <div className="flex items-center text-blue-600">
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    <span className="text-xs">Loading</span>
                  </div>
                ) : (
                  <div className="flex items-center text-green-600">
                    <CheckCircle2 className="h-4 w-4 mr-2" />
                    <span className="text-xs">Ready</span>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
} 