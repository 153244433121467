// src/Router.tsx
import React from "react";
import { 
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";

import { useCampaignData } from "@/contexts/campaign-data";
import RootLayout from "@/components/layouts/root-layout";
import { useUser } from "@clerk/clerk-react";

// Import pages
import Index from "@/pages/index";
import Analysis from "@/pages/analysis";
import Trends from "@/pages/trends";
import Curve from "@/pages/curve";
import Settings from "@/pages/settings";
import Hourly from "@/pages/hourly";
import Scripts from "@/pages/scripts";
import SignInPage from "@/pages/auth/sign-in";
import SignUpPage from "@/pages/auth/sign-up";
import LearnGoogleAdsScripts from "@/pages/scary";
import NotFound from "@/pages/not-found";
import Help from "@/pages/help";
import Setup from '@/pages/setup'
import Story from "@/pages/story";

const DEBUG = process.env.NODE_ENV === 'development'

function LoadingState() {
  if (DEBUG) console.log('Router: Showing loading state')
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isSignedIn, isLoaded: isAuthLoaded } = useUser();
  const { useSampleData, isInitialized } = useCampaignData();
  const location = useLocation();
  
  if (!isAuthLoaded || !isInitialized) {
    return <LoadingState />;
  }

  if (!isSignedIn && !useSampleData) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}

export default function AppRoutes() {
  return (
    <RootLayout>
      <Routes>
        {/* Public routes */}
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        
        {/* Protected routes */}
        <Route path="/" element={<ProtectedRoute><Index /></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path="/analysis" element={<ProtectedRoute><Analysis /></ProtectedRoute>} />
        <Route path="/trends" element={<ProtectedRoute><Trends /></ProtectedRoute>} />
        <Route path="/curve" element={<ProtectedRoute><Curve /></ProtectedRoute>} />
        <Route path="/hourly" element={<ProtectedRoute><Hourly /></ProtectedRoute>} />
        <Route path="/scripts" element={<ProtectedRoute><Scripts /></ProtectedRoute>} />
        <Route path="/scary" element={<ProtectedRoute><LearnGoogleAdsScripts /></ProtectedRoute>} />
        <Route path="/help" element={<ProtectedRoute><Help /></ProtectedRoute>} />
        <Route path="/setup" element={<ProtectedRoute><Setup /></ProtectedRoute>} />
        <Route path="/story" element={<ProtectedRoute><Story /></ProtectedRoute>} />
        
        {/* Catch all */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RootLayout>
  );
}