// src/lib/storage-utils.ts
import { STORAGE_KEYS, CACHE_DURATION } from './constants';
import type { 
  StorageData,
  DailyData,
  HourlyData,
  ThirtyDayData,
  PreviousThirtyDayData,
  SevenDayData,
  PreviousSevenDayData,
  CampaignSettings,
  ProductData,
  SearchTermData,
  PMaxData
} from '@/types/metrics';

// Validation helpers
const isValidArray = (arr: unknown): arr is unknown[] => Array.isArray(arr);

const isValidTimestamp = (timestamp: unknown): timestamp is string => {
  if (typeof timestamp !== 'string') return false;
  const date = new Date(timestamp);
  return !isNaN(date.getTime());
};

const validateStorageData = (data: unknown): data is StorageData => {
  if (!data || typeof data !== 'object') return false;
  
  const storageData = data as Partial<StorageData>;
  
  // Check timestamp
  if (!isValidTimestamp(storageData.timestamp)) return false;
  
  // Check all required arrays exist and are arrays
  const requiredArrays: (keyof StorageData)[] = [
    'account',
    'daily',
    'hourly',
    'hourly_yest',
    'thirty_days',
    'p_thirty_days',
    'seven_days',
    'p_seven_days',
    'settings',
    'products',
    'pmax_perf',
    'search_terms'
  ];
  
  return requiredArrays.every(key => isValidArray(storageData[key]));
};

// Lock mechanism
let isStorageLocked = false;
const lockTimeout = 5000;

export async function withStorageLock<T>(operation: () => Promise<T>): Promise<T> {
  if (isStorageLocked) {
    await new Promise(resolve => setTimeout(resolve, 100));
    return withStorageLock(operation);
  }

  let timeoutId: NodeJS.Timeout;
  try {
    isStorageLocked = true;
    
    // Set timeout to release lock
    timeoutId = setTimeout(() => {
      console.warn('Storage lock timeout - forcing release');
      isStorageLocked = false;
    }, lockTimeout);
    
    const result = await operation();
    return result;
  } finally {
    clearTimeout(timeoutId!);
    isStorageLocked = false;
  }
}

export function loadStorageData(): StorageData | null {
  try {
    const cached = localStorage.getItem(STORAGE_KEYS.CAMPAIGN_DATA);
    if (!cached) return null;
    
    const parsed = JSON.parse(cached);
    
    // Validate data structure
    if (!validateStorageData(parsed)) {
      throw new Error('Invalid storage data structure');
    }
    
    // Check cache freshness
    const cacheAge = new Date().getTime() - new Date(parsed.timestamp).getTime();
    if (cacheAge > CACHE_DURATION) {
      localStorage.removeItem(STORAGE_KEYS.CAMPAIGN_DATA);
      return null;
    }
    
    return parsed;
  } catch (error) {
    console.error('Error loading cached data:', error);
    localStorage.removeItem(STORAGE_KEYS.CAMPAIGN_DATA);
    return null;
  }
}

export function saveStorageData(data: Partial<StorageData> & { dexieId: string }): StorageData {
  // Create new storage object with current timestamp
  const storageData: StorageData = {
    timestamp: new Date().toISOString(),
    dexieId: data.dexieId,
    account: [],
    daily: [],
    hourly: [],
    hourly_yest: [],
    thirty_days: [],
    p_thirty_days: [],
    seven_days: [],
    p_seven_days: [],
    settings: [],
    products: [],
    pmax_perf: [],
    search_terms: [],
    ...data // Merge in provided data
  };
  
  // Validate before saving
  if (!validateStorageData(storageData)) {
    throw new Error('Invalid data structure for storage');
  }
  
  localStorage.setItem(STORAGE_KEYS.CAMPAIGN_DATA, JSON.stringify(storageData));
  return storageData;
}

// Helper to check if data needs refresh
export function needsRefresh(data: StorageData | null): boolean {
  if (!data?.timestamp) return true;
  
  const cacheAge = new Date().getTime() - new Date(data.timestamp).getTime();
  return cacheAge > CACHE_DURATION;
}

// Helper to merge new data with existing cached data
export function mergeStorageData(
  existing: StorageData | null, 
  newData: Partial<StorageData> & { dexieId: string }
): StorageData {
  const merged: StorageData = {
    ...(existing || { 
      dexieId: newData.dexieId,
      account: [],
      daily: [],
      hourly: [],
      hourly_yest: [],
      thirty_days: [],
      p_thirty_days: [],
      seven_days: [],
      p_seven_days: [],
      settings: [],
      products: [],
      pmax_perf: [],
      search_terms: []
    }),
    ...newData,
    timestamp: new Date().toISOString()
  };
  
  return saveStorageData(merged);
}

// Add these functions to handle local storage
export const LOCAL_STORAGE_KEYS = {
  COGS_AMOUNT: 'temp_cogs_amount',
  BREAK_EVEN_CPA: 'temp_break_even_cpa',
  LAST_CLERK_UPDATE: 'last_clerk_update'
} as const;

export function setLocalValue(key: string, value: number) {
  localStorage.setItem(key, value.toString());
  localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_CLERK_UPDATE, Date.now().toString());
}

export function getLocalValue(key: string): number | null {
  const value = localStorage.getItem(key);
  return value ? Number(value) : null;
}

const TEMP_WEBAPP_URL_KEY = 'temp_webapp_url'

export function storeTempWebAppUrl(url: string) {
  localStorage.setItem(TEMP_WEBAPP_URL_KEY, url)
}

export function getTempWebAppUrl(): string | null {
  return localStorage.getItem(TEMP_WEBAPP_URL_KEY)
}

export function clearTempWebAppUrl() {
  localStorage.removeItem(TEMP_WEBAPP_URL_KEY)
}

// Add a timestamp to know when it was stored
export function storeTempWebAppUrlWithTimestamp(url: string) {
  const data = {
    url,
    timestamp: new Date().toISOString()
  }
  localStorage.setItem(TEMP_WEBAPP_URL_KEY, JSON.stringify(data))
}

export function getTempWebAppUrlWithTimestamp(): { url: string; timestamp: string } | null {
  const data = localStorage.getItem(TEMP_WEBAPP_URL_KEY)
  if (!data) return null
  try {
    return JSON.parse(data)
  } catch {
    return null
  }
}