// src/lib/data-utils.ts
import { StorageData, Account } from '@/types/metrics'
import { SHEET_TABS, SAMPLE_ACCOUNT_ID } from '@/lib/constants'
import { loadSampleData } from './sample-data-utils'

const DEBUG = process.env.NODE_ENV === 'development'

export function createEmptyDataStructure(dexieId: string, account: Account): StorageData {
  return {
    timestamp: new Date().toISOString(),
    dexieId,
    accountCID: account.accountCID,
    [SHEET_TABS.ACCOUNT]: [{
      accountName: account.name
    }],
    [SHEET_TABS.DAILY]: [],
    [SHEET_TABS.HOURLY]: [],
    [SHEET_TABS.HOURLY_YEST]: [],
    [SHEET_TABS.THIRTY_DAYS]: [],
    [SHEET_TABS.P_THIRTY_DAYS]: [],
    [SHEET_TABS.SEVEN_DAYS]: [],
    [SHEET_TABS.P_SEVEN_DAYS]: [],
    [SHEET_TABS.SETTINGS]: [],
    [SHEET_TABS.PRODUCTS]: [],
    [SHEET_TABS.SEARCH_TERMS]: [],
    [SHEET_TABS.PMAX_PERF]: []
  }
}

export async function loadSampleDataFromCsv(): Promise<StorageData> {
  console.log('loadSampleDataFromCsv: Starting')
  const data = await loadSampleData()
  console.log('loadSampleDataFromCsv: Loaded data:', {
    hasThirtyDays: !!data[SHEET_TABS.THIRTY_DAYS]?.length,
    thirtyDaysCount: data[SHEET_TABS.THIRTY_DAYS]?.length,
    firstCampaign: data[SHEET_TABS.THIRTY_DAYS]?.[0]
  })
  return data
}

export async function fetchAllData(dexieId: string, webAppUrl: string): Promise<StorageData> {
  console.log('fetchAllData: Starting fetch for:', { dexieId, webAppUrl })

  // Handle sample data
  if (dexieId === SAMPLE_ACCOUNT_ID) {
    return loadSampleDataFromCsv()
  }

  try {
    const dataMap = new Map();
    
    // Fetch all tabs in parallel
    console.log('fetchAllData: Starting parallel tab fetches')
    await Promise.all(Object.values(SHEET_TABS).map(async tab => {
      try {
        const url = `${webAppUrl}?tab=${tab}`
        console.log(`fetchAllData: Fetching ${tab} from ${url}`)
        
        const response = await fetch(url)
        if (!response.ok) {
          console.error(`fetchAllData: Failed to fetch ${tab}:`, response.statusText)
          throw new Error(`Failed to fetch ${tab} data: ${response.statusText}`)
        }
        
        const data = await response.json()
        console.log(`fetchAllData: Received ${tab} data:`, {
          isArray: Array.isArray(data),
          length: Array.isArray(data) ? data.length : 'N/A'
        })
        
        dataMap.set(tab, data)
      } catch (err) {
        console.error(`fetchAllData: Error fetching ${tab}:`, err)
        dataMap.set(tab, [])
      }
    }))

    console.log('fetchAllData: All tabs fetched, constructing response')
    const result = {
      timestamp: new Date().toISOString(),
      dexieId,
      accountCID: dataMap.get(SHEET_TABS.ACCOUNT)?.[0]?.accountCID || dexieId,
      [SHEET_TABS.ACCOUNT]: dataMap.get(SHEET_TABS.ACCOUNT) || [],
      [SHEET_TABS.DAILY]: dataMap.get(SHEET_TABS.DAILY) || [],
      [SHEET_TABS.HOURLY]: dataMap.get(SHEET_TABS.HOURLY) || [],
      [SHEET_TABS.HOURLY_YEST]: dataMap.get(SHEET_TABS.HOURLY_YEST) || [],
      [SHEET_TABS.THIRTY_DAYS]: dataMap.get(SHEET_TABS.THIRTY_DAYS) || [],
      [SHEET_TABS.P_THIRTY_DAYS]: dataMap.get(SHEET_TABS.P_THIRTY_DAYS) || [],
      [SHEET_TABS.SEVEN_DAYS]: dataMap.get(SHEET_TABS.SEVEN_DAYS) || [],
      [SHEET_TABS.P_SEVEN_DAYS]: dataMap.get(SHEET_TABS.P_SEVEN_DAYS) || [],
      [SHEET_TABS.SETTINGS]: dataMap.get(SHEET_TABS.SETTINGS) || [],
      [SHEET_TABS.PRODUCTS]: dataMap.get(SHEET_TABS.PRODUCTS) || [],
      [SHEET_TABS.SEARCH_TERMS]: dataMap.get(SHEET_TABS.SEARCH_TERMS) || [],
      [SHEET_TABS.PMAX_PERF]: dataMap.get(SHEET_TABS.PMAX_PERF) || []
    }
    
    console.log('fetchAllData: Response constructed:', {
      timestamp: result.timestamp,
      dexieId: result.dexieId,
      hasAccount: !!result[SHEET_TABS.ACCOUNT]?.length,
      hasDaily: !!result[SHEET_TABS.DAILY]?.length,
      hasThirtyDays: !!result[SHEET_TABS.THIRTY_DAYS]?.length
    })
    
    return result
  } catch (err) {
    console.error('fetchAllData: Failed to fetch data:', err)
    throw new Error('Failed to fetch data: ' + (err instanceof Error ? err.message : 'Unknown error'))
  }
}