import { Slider } from "@/components/ui/slider"
import { cn } from "@/lib/utils"
import { CampaignProjection } from "./types"

interface BudgetSliderProps {
  projection: CampaignProjection;
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

export function BudgetSlider({
  projection,
  value,
  onChange,
  disabled
}: BudgetSliderProps) {
  // Ensure value is within bounds and a multiple of 5
  const handleChange = (values: number[]) => {
    const newValue = Math.round(values[0] / 5) * 5;
    onChange(Math.max(-99, Math.min(100, newValue)));
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2 min-w-[200px]">
        <Slider
          min={-99}
          max={100}
          step={5}
          disabled={disabled}
          value={[value]}
          onValueChange={handleChange}
          className={cn(
            "flex-grow",
            disabled && "opacity-50",
            !disabled && value > 0 && "[&>.relative>.bg-primary]:bg-green-500",
            !disabled && value < 0 && "[&>.relative>.bg-primary]:bg-red-500",
            !disabled && value === 0 && "[&>.relative>.bg-primary]:bg-muted-foreground"
          )}
        />
        <span className="text-sm w-12 text-right">
          {value}%
        </span>
      </div>
    </div>
  );
}