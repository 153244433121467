// src/main.tsx
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { ClerkProvider, useAuth } from "@clerk/clerk-react"
import { ThemeProvider } from "@/components/theme-provider"
import { BrowserRouter } from 'react-router-dom'
import { AccountProvider } from '@/contexts/account-context'
import { CampaignDataProvider } from '@/contexts/campaign-data'
import AppRoutes from './Router'
import './index.css'

// Environment detection
const isDev = import.meta.env.MODE === 'development' || 
             window.location.hostname.includes('replit.dev');

// Get the correct key based on environment
const CLERK_KEY = isDev 
  ? import.meta.env.VITE_CLERK_DEV_KEY 
  : import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!CLERK_KEY) {
  console.error('Missing Clerk Key!')
  throw new Error(`Missing Clerk Key for ${isDev ? 'development' : 'production'}`)
}

// Development session cleanup
function DevSessionCleanup() {
  const { signOut } = useAuth()
  
  useEffect(() => {
    const clearDevSession = async () => {
      try {
        // Check if we have a stale development session
        const sessionKey = localStorage.getItem('clerk-db-jwt')
        if (sessionKey && isDev) {
          console.log('Clearing development session...')
          await signOut()
          localStorage.removeItem('clerk-db-jwt')
        }
      } catch (err) {
        console.error('Failed to clear dev session:', err)
      }
    }
    clearDevSession()
  }, [signOut])
  
  return null
}

const App = () => {
  useEffect(() => {
    console.log('Environment:', {
      mode: isDev ? 'development' : 'production',
      clerkKey: CLERK_KEY ? `${CLERK_KEY.substring(0, 8)}...` : 'MISSING',
      workspace: window.location.hostname
    })
  }, [])

  return (
    <ClerkProvider 
      publishableKey={CLERK_KEY}
      appearance={{
        elements: {
          rootBox: "mx-auto w-full max-w-[440px] mt-[-100px]",
          card: "shadow-none",
          formButtonPrimary: "bg-primary hover:bg-primary/90",
          footerAction: "font-normal",
          formField: "gap-1",
          form: "gap-1",
          formFieldRow: "gap-3"
        },
        layout: {
          socialButtonsPlacement: "bottom",
          socialButtonsVariant: "iconButton",
          privacyPageUrl: "https://your-privacy-page",
          termsPageUrl: "https://your-terms-page"
        }
      }}
    >
      <ThemeProvider defaultTheme="light">
        <BrowserRouter>
          <AccountProvider>
            <CampaignDataProvider>
              {isDev && <DevSessionCleanup />}
              <AppRoutes />
            </CampaignDataProvider>
          </AccountProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ClerkProvider>
  )
}

try {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
} catch (err) {
  console.error('Main: Fatal error during render:', err)
}