// src/components/budget-optimization/budget-optimization.tsx

import { useState, useMemo, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BudgetSlider } from "./budget-slider";
import { useCampaignProjections } from "./use-campaign-projections";
import { CampaignProjection, OptimizationMode, ProjectionFilter } from "./types";
import type { CampaignWithShare, BusinessMode, ResponseModel } from '@/types/metrics';
import { useAccounts } from '@/contexts/account-context';

interface BudgetOptimizationProps {
    campaigns: CampaignWithShare[];
    cogsPercentage: number;
    breakEvenCpa: number;
    businessMode: BusinessMode;
    includeFilter: string;
    excludeFilter: string;
    rowLimit: number;
    optimizationMode: OptimizationMode;
    onProjectionsChange?: (projections: CampaignProjection[]) => void;
    accountCurrency: string;
    onFilterChange?: (filters: { includeFilter: string, excludeFilter: string }) => void;
}

interface MetricDisplayProps {
    label: string;
    value: number;
    previousValue: number;
    formatter?: (n: number) => string;
    inverse?: boolean;
}

const handleBudgetTabClick = (event: React.MouseEvent) => {
    if ((event.target as HTMLElement).closest('[role="tab"]')) {
        event.preventDefault();
    }
};

function MetricDisplay({
    label,
    value,
    previousValue,
    formatter = (n) => Math.round(n).toLocaleString(),
    inverse = false,
}: MetricDisplayProps) {
    // Handle NaN values
    const safeValue = isNaN(value) ? 0 : value;
    const safePreviousValue = isNaN(previousValue) ? 0 : previousValue;

    const percentChange = safePreviousValue ?
        ((safeValue - safePreviousValue) / Math.abs(safePreviousValue)) * 100 : 0;
    const formattedValue = formatter(safeValue);

    return (
        <div className="text-center">
            <p className="text-sm font-medium">{label}</p>
            <div className="mt-1">
                <p className="text-xl font-bold">{formattedValue}</p>
                {percentChange !== 0 && !isNaN(percentChange) && (
                    <p className={`text-sm ${percentChange === 0 ? 'text-muted-foreground' :
                        percentChange > 0 === !inverse ? 'text-green-500' : 'text-red-500'
                        }`}>
                        {percentChange > 0 ? '↑' : '↓'} {Math.abs(Math.round(percentChange))}%
                    </p>
                )}
            </div>
        </div>
    );
}

export function BudgetOptimization({
    campaigns,
    cogsPercentage,
    breakEvenCpa,
    businessMode,
    includeFilter = '',
    excludeFilter = '',
    rowLimit = 100,
    optimizationMode = 'none',
    onProjectionsChange,
    accountCurrency,
    onFilterChange
}: BudgetOptimizationProps) {
    const [selectedMode, setSelectedMode] = useState<OptimizationMode>(optimizationMode);
    const [responseModel, setResponseModel] = useState<ResponseModel>('diminishing');
    const [filter, setFilter] = useState<ProjectionFilter>('all');
    const { activeAccount } = useAccounts();

    // Add event listener for clearing filters
    useEffect(() => {
        const handleClearFilters = () => {
            if (onFilterChange) {
                onFilterChange({ includeFilter: '', excludeFilter: '' });
            }
        };

        window.addEventListener('clearBudgetFilters', handleClearFilters);
        return () => window.removeEventListener('clearBudgetFilters', handleClearFilters);
    }, [onFilterChange]);

    // Process campaigns with response model
    const {
        campaignProjections,
        projectedMetrics,
        rowAdjustments,
        setRowAdjustments
    } = useCampaignProjections(
        campaigns,
        cogsPercentage,
        optimizationMode,
        includeFilter,
        excludeFilter,
        accountCurrency,
        responseModel,
        businessMode,
        breakEvenCpa
    );

    // Filter projections based on user selection
    const filteredProjections = useMemo(() => {
        const filtered = campaignProjections
            .filter(projection => {
                const currentAdjustment = rowAdjustments[projection.Campaign]?.adjustment ?? 0;

                if (projection.isHighIS) {
                    return filter === 'high-is' || filter === 'all';
                }

                switch (filter) {
                    case 'increase':
                        return currentAdjustment > 0;
                    case 'decrease':
                        return currentAdjustment < 0;
                    case 'nochange':
                        return currentAdjustment === 0 && !projection.isHighIS;
                    case 'high-is':
                        return projection.isHighIS;
                    default:
                        return true;
                }
            })
            .slice(0, rowLimit);

        return filtered;
    }, [campaignProjections, filter, rowAdjustments, rowLimit]);

    const getStatusDisplay = (projection: CampaignProjection) => {
        if (projection.isHighIS) {
            return (
                <span className="text-blue-600">
                    No Change (High IS)
                </span>
            );
        }

        return <span className={
            projection.recommendation === 'Increase Cost' ? 'text-green-600' :
                projection.recommendation === 'Decrease Cost' ? 'text-red-600' :
                    'text-blue-600'  // No Change
        }>
            {projection.recommendation}
        </span>;
    };

    return (
        <div className="space-y-6">
            {/* Projected Performance Impact */}
            <Card className="mb-8">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Projected Performance Impact (for filtered campaigns)</CardTitle>
                        <Tabs
                            value={responseModel}
                            onValueChange={(value) => setResponseModel(value as ResponseModel)}
                            className="w-fit"
                        >
                            <TabsList className="bg-transparent gap-2">
                                <TabsTrigger
                                    value="linear"
                                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                                    text-muted-foreground hover:bg-primary/10 transition-colors"
                                >
                                    Linear
                                </TabsTrigger>
                                <TabsTrigger
                                    value="diminishing"
                                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                                    text-muted-foreground hover:bg-primary/10 transition-colors"
                                >
                                    Diminishing
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-10 gap-3">
                        <MetricDisplay
                            label="Impr"
                            value={projectedMetrics.projected.impressions}
                            previousValue={projectedMetrics.current.impressions}
                            formatter={(n) => Math.round(n).toLocaleString()}
                        />
                        <MetricDisplay
                            label="Clicks"
                            value={projectedMetrics.projected.clicks}
                            previousValue={projectedMetrics.current.clicks}
                            formatter={(n) => Math.round(n).toLocaleString()}
                        />
                        <MetricDisplay
                            label="Cost"
                            value={projectedMetrics.projected.cost}
                            previousValue={projectedMetrics.current.cost}
                            formatter={(n) => `${accountCurrency}${Math.round(n).toLocaleString()}`}
                            inverse={true}
                        />
                        <MetricDisplay
                            label="Conv"
                            value={projectedMetrics.projected.conversions}
                            previousValue={projectedMetrics.current.conversions}
                            formatter={(n) => Math.round(n).toLocaleString()}
                        />
                        <MetricDisplay
                            label="Value"
                            value={projectedMetrics.projected.revenue}
                            previousValue={projectedMetrics.current.revenue}
                            formatter={(n) => `${accountCurrency}${Math.round(n).toLocaleString()}`}
                        />
                        <MetricDisplay
                            label="CTR"
                            value={projectedMetrics.projected.clicks > 0
                                ? (projectedMetrics.projected.clicks / projectedMetrics.projected.impressions * 100)
                                : 0}
                            previousValue={projectedMetrics.current.clicks > 0
                                ? (projectedMetrics.current.clicks / projectedMetrics.current.impressions * 100)
                                : 0}
                            formatter={(n) => `${n.toFixed(1)}%`}
                        />
                        <MetricDisplay
                            label="CvR"
                            value={projectedMetrics.projected.clicks > 0
                                ? (projectedMetrics.projected.conversions / projectedMetrics.projected.clicks * 100)
                                : 0}
                            previousValue={projectedMetrics.current.clicks > 0
                                ? (projectedMetrics.current.conversions / projectedMetrics.current.clicks * 100)
                                : 0}
                            formatter={(n) => `${n.toFixed(1)}%`}
                        />
                        <MetricDisplay
                            label="CPC"
                            value={projectedMetrics.projected.clicks > 0
                                ? projectedMetrics.projected.cost / projectedMetrics.projected.clicks
                                : 0}
                            previousValue={projectedMetrics.current.clicks > 0
                                ? projectedMetrics.current.cost / projectedMetrics.current.clicks
                                : 0}
                            formatter={(n) => n === 0 ? '-' : `${accountCurrency}${n.toFixed(2)}`}
                            inverse={true}
                        />
                        <MetricDisplay
                            label="CPA"
                            value={projectedMetrics.projected.conversions > 0
                                ? projectedMetrics.projected.cost / projectedMetrics.projected.conversions
                                : 0}
                            previousValue={projectedMetrics.current.conversions > 0
                                ? projectedMetrics.current.cost / projectedMetrics.current.conversions
                                : 0}
                            formatter={(n) => n === 0 ? '-' : `${accountCurrency}${n.toFixed(2)}`}
                            inverse={true}
                        />
                        <MetricDisplay
                            label="ROAS"
                            value={projectedMetrics.projected.cost > 0
                                ? projectedMetrics.projected.revenue / projectedMetrics.projected.cost
                                : 0}
                            previousValue={projectedMetrics.current.cost > 0
                                ? projectedMetrics.current.revenue / projectedMetrics.current.cost
                                : 0}
                            formatter={(n) => n.toFixed(2)}
                        />
                    </div>
                </CardContent>
            </Card>

            {/* Budget Change Projections */}
            <Card>
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Budget Change Projections</CardTitle>
                        <Tabs
                            value={filter}
                            onValueChange={(value) => setFilter(value as ProjectionFilter)}
                        >
                            <TabsList>
                                <TabsTrigger value="all" onClick={handleBudgetTabClick}>All Changes</TabsTrigger>
                                <TabsTrigger value="increase" onClick={handleBudgetTabClick}>Increases</TabsTrigger>
                                <TabsTrigger value="decrease" onClick={handleBudgetTabClick}>Decreases</TabsTrigger>
                                <TabsTrigger value="nochange" onClick={handleBudgetTabClick}>No Change</TabsTrigger>
                                <TabsTrigger value="high-is" onClick={handleBudgetTabClick}>High IS</TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="min-h-[200px]">
                        {filteredProjections.length > 0 ? (
                            <table className="w-full text-sm text-left">
                                <thead>
                                    <tr className="border-b">
                                        <th className="px-4 py-2 text-left w-[350px]">Campaign</th>
                                        <th className="px-4 py-2 text-right w-[200px]">Projected Cost</th>
                                        <th className="px-4 py-2 text-right w-[200px]">Adjustment</th>
                                        <th className="px-4 py-2 text-right w-[150px]">Profit Change</th>
                                        <th className="px-4 py-2 text-left w-[120px]">Action</th>
                                        <th className="px-4 py-2 text-left w-[20%]">Reason for Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredProjections.map(projection => (
                                        <tr key={projection.Campaign} className="border-b">
                                            <td className="px-4 py-2">
                                                {projection.Campaign}
                                                {projection.isHighIS && (
                                                    <span className="ml-2 text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800">
                                                        {Math.round(projection.currentIS)}% IS
                                                    </span>
                                                )}
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                {accountCurrency}{Math.round(projection.projectedCost).toLocaleString()}
                                                <span className="text-muted-foreground ml-2">
                                                    ({projection.costChange >= 0 ? '+' : ''}{accountCurrency}{Math.round(Math.abs(projection.costChange)).toLocaleString()})
                                                </span>
                                            </td>
                                            <td className="px-4 py-2">
                                                <BudgetSlider
                                                    projection={projection}
                                                    value={rowAdjustments[projection.Campaign]?.adjustment ?? 0}
                                                    onChange={(value) => {
                                                        setRowAdjustments(prev => ({
                                                            ...prev,
                                                            [projection.Campaign]: {
                                                                campaignName: projection.Campaign,
                                                                adjustment: value
                                                            }
                                                        }));
                                                    }}
                                                    disabled={projection.isHighIS}
                                                />
                                            </td>
                                            <td className="px-4 py-2 text-right">
                                                <span className={projection.profitChange < 0 ? 'text-red-500' : 'text-green-500'}>
                                                    {accountCurrency}{Math.round(projection.profitChange).toLocaleString()}
                                                    {projection.profitChange !== 0 && projection.currentProfit !== 0 && Math.abs(projection.currentProfit) > 1 && (
                                                        <span className="ml-1 text-sm">
                                                            ({((projection.profitChange / Math.abs(projection.currentProfit)) * 100).toFixed(1)}%)
                                                        </span>
                                                    )}
                                                </span>
                                            </td>
                                            <td className="px-4 py-2">{getStatusDisplay(projection)}</td>
                                            <td className="px-4 py-2">{projection.changeReason}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="flex items-center justify-center h-[200px] text-muted-foreground">
                                No campaigns match your filter criteria
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}