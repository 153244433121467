// src/config/menu.ts
import { Icons } from "@/components/icons"
import { levels } from "@/lib/script-levels";

export const getScaryNavTitle = (currentGameLevel: number | null | undefined): string => {
  if (currentGameLevel === null || currentGameLevel === undefined) return "Scary!";
  if (currentGameLevel === levels.length - 1) return "Master!"; // Level 10 is the finish line
  if (currentGameLevel === 0) return "Scary!";
  return `Level ${currentGameLevel + 1}`;
};

interface NavItem {
    title: string
    to?: string
    href?: string
    disabled?: boolean
    external?: boolean
    icon?: keyof typeof Icons
    label?: string
    hidden?: boolean
}

interface NavItemWithChildren extends NavItem {
    items?: NavItemWithChildren[]
}

export const mainNav: NavItem[] = [
    {
        title: "Home",
        href: "/",
        hidden: true, // Hide from navbar since it's accessed via logo
    },
    {
        title: "Setup",
        href: "/setup",
        hidden: true, // typically accessed through other flows
    },
    {
        title: "Settings",
        href: "/settings",
        hidden: true, // Now accessed via gear icon
    },
    {
        title: "Analysis",
        href: "/analysis",
    },
    {
        title: "Trends",
        href: "/trends",
    },
    {
        title: "Curve",
        href: "/curve",
    },
    {
        title: "Hourly",
        href: "/hourly",
    },
    {
        title: "Scripts",
        href: "/scripts",
    },
    {
        title: "Scary",
        href: "/scary",
    },
    {
        title: "Story",
        href: "/story",
    },

];

export const sideMenu: NavItemWithChildren[] = []

export const getUpdatedNav = (currentGameLevel: number | null | undefined): NavItem[] => {
    return mainNav.map(item => 
      item.href === "/scary" 
        ? { ...item, title: getScaryNavTitle(currentGameLevel) }
        : item
    );
  };