// src/components/pmax-trends.tsx

import { useMemo, useState, useEffect } from 'react';
import { Line, LineChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, CartesianGrid } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LineChart as LineChartIcon, BarChart as BarChartIcon, BarChart3 } from 'lucide-react';
import { PMAX_METRICS, PMAX_CHANNELS, PMaxMetricKey, PMaxChannel, DEBUG } from '@/lib/constants';

type ChartType = 'line' | 'bar' | 'stacked';

interface PMAxSectionProps {
    pmaxData: any[];
    selectedCampaign: string;
    timeRange: '7' | '30' | '90';
    latestDate: Date;
    currency: string;
}

const PMaxSection = ({
    pmaxData,
    selectedCampaign,
    timeRange,
    latestDate,
    currency
}: PMAxSectionProps) => {
    const [chartType, setChartType] = useState<ChartType>('line');
    const [selectedMetric, setSelectedMetric] = useState<PMaxMetricKey>('cost');

    const channelColors = {
        Shopping: '#115e59',
        Display: '#d1d5db',
        Video: '#f97316',
        Search: '#93c5fd'
    } as const;

    const transformPMaxData = (row: any) => {
        const baseData = {
            date: row.date,
        };

        // Map channel-specific metrics
        PMAX_CHANNELS.forEach((channel) => {
            const costKey = `${channel.toLowerCase()}_cost`;
            const convKey = `${channel.toLowerCase()}_conv`;
            const valueKey = `${channel.toLowerCase()}_value`;

            const cost = row[costKey] || 0;
            const conv = row[convKey] || 0;
            const value = row[valueKey] || 0;

            const totalCost = row.total_cost || 0;
            const totalConv = row.total_conv || 0;
            const totalValue = row.total_value || 0;

            switch (selectedMetric) {
                case "cost":
                    baseData[channel] = cost;
                    break;
                case "cost_share":
                    baseData[channel] = totalCost ? (cost / totalCost) * 100 : 0;
                    break;
                case "conversions":
                    baseData[channel] = conv;
                    break;
                case "conv_share":
                    baseData[channel] = totalConv ? (conv / totalConv) * 100 : 0;
                    break;
                case "value":
                    baseData[channel] = value;
                    break;
                case "value_share":
                    baseData[channel] = totalValue ? (value / totalValue) * 100 : 0;
                    break;
            }
        });

        return baseData;
    };

    const processedData = useMemo(() => {
        if (!pmaxData?.length || !selectedCampaign) return [];
      
        const daysToSubtract = parseInt(timeRange);
        const startDate = new Date(latestDate);
        startDate.setDate(startDate.getDate() - daysToSubtract);
    
        const filtered = pmaxData
            .filter((row) => {
                const rowDate = new Date(row.Date);
                return (
                    row.Campaign === selectedCampaign &&
                    rowDate >= startDate &&
                    rowDate <= latestDate
                );
            });
    
        return filtered
            .map((row) => {
                const baseData = {
                    date: row.Date,
                };
      
                // Map channel-specific metrics
                PMAX_CHANNELS.forEach((channel) => {
                    const costKey = `${channel} Cost`;
                    const convKey = `${channel} Conv`;
                    const valueKey = `${channel} Value`;
        
                    const cost = Number(row[costKey]) || 0;
                    const conv = Number(row[convKey]) || 0;
                    const value = Number(row[valueKey]) || 0;
        
                    const totalCost = Number(row["Total Cost"]) || 0;
                    const totalConv = Number(row["Total Conv"]) || 0;
                    const totalValue = Number(row["Total Value"]) || 0;
        
                    switch (selectedMetric) {
                        case "cost":
                            baseData[channel] = cost;
                            break;
                        case "cost_share":
                            baseData[channel] = totalCost ? (cost / totalCost) * 100 : 0;
                            break;
                        case "conversions":
                            baseData[channel] = conv;
                            break;
                        case "conv_share":
                            baseData[channel] = totalConv ? (conv / totalConv) * 100 : 0;
                            break;
                        case "value":
                            baseData[channel] = value;
                            break;
                        case "value_share":
                            baseData[channel] = totalValue ? (value / totalValue) * 100 : 0;
                            break;
                    }
                });
      
                return baseData;
            })
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }, [pmaxData, selectedCampaign, timeRange, latestDate, selectedMetric]);

    const formatDateShort = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short'
        });
    };

    const formatDateLong = (value: string) => {
        return new Date(value).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    };

    // Early return if no data
    if (!pmaxData?.length || !selectedCampaign) {
        return (
            <Card className="bg-muted/50">
                <CardContent className="flex flex-col items-center justify-center py-12 text-center">
                    <h3 className="text-lg font-medium mb-2">No PMax Data Available</h3>
                    <p className="text-muted-foreground">
                        No Performance Max data available for the selected campaign.
                    </p>
                </CardContent>
            </Card>
        );
    }

    return (
        <div className="space-y-2">
            <div className="grid grid-cols-6 gap-2">
                {PMAX_METRICS.map(metric => (
                    <Card
                        key={metric.key}
                        onClick={() => setSelectedMetric(metric.key)}
                        className={`cursor-pointer transition-colors hover:bg-accent ${
                            selectedMetric === metric.key ? 'ring-2 ring-primary' : ''
                        }`}
                    >
                        <CardHeader className="pb-1 pt-2 px-3 space-y-0">
                            <CardTitle className="text-xs font-medium">{metric.label}</CardTitle>
                        </CardHeader>
                    </Card>
                ))}
            </div>

            <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">Performance Max Channel Analysis</h2>
                <Tabs value={chartType} onValueChange={(value) => setChartType(value as ChartType)}>
                    <TabsList className="grid w-fit grid-cols-3">
                        <TabsTrigger value="line" className="px-4">
                            <LineChartIcon className="h-4 w-4 mr-2" />
                            Line
                        </TabsTrigger>
                        <TabsTrigger value="bar" className="px-4">
                            <BarChartIcon className="h-4 w-4 mr-2" />
                            Bar
                        </TabsTrigger>
                        <TabsTrigger value="stacked" className="px-4">
                            <BarChart3 className="h-4 w-4 mr-2" />
                            Stacked
                        </TabsTrigger>
                    </TabsList>
                </Tabs>
            </div>

            <Card>
                <CardContent className="pt-6">
                    <div className="h-96">
                        <ResponsiveContainer width="100%" height="100%">
                            {renderChart(chartType, processedData)}
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    );

    function renderChart(type: ChartType, data: any[]) {
        const selectedMetricConfig = PMAX_METRICS.find(m => m.key === selectedMetric);
        const yAxisLabel = selectedMetricConfig?.label || '';
        const formatValue = selectedMetricConfig?.format || ((v: number) => v.toString());

        switch(type) {
            case 'line':
                return (
                    <LineChart data={data}>
                        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            tickFormatter={formatDateShort}
                        />
                        <YAxis
                            tickFormatter={value => formatValue(value)}
                            label={{
                                value: yAxisLabel,
                                angle: -90,
                                position: 'insideLeft',
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <Tooltip
                            formatter={(value: number) => [formatValue(value), yAxisLabel]}
                            labelFormatter={formatDateLong}
                        />
                        <Legend />
                        {PMAX_CHANNELS.map(channel => (
                            <Line
                                key={channel}
                                type="monotone"
                                dataKey={channel}
                                stroke={channelColors[channel]}
                                name={channel}
                                dot={false}
                                strokeWidth={2}
                                animationDuration={5}
                            />
                        ))}
                    </LineChart>
                );
                
            case 'bar':
                return (
                    <BarChart data={data}>
                        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            tickFormatter={formatDateShort}
                        />
                        <YAxis
                            tickFormatter={value => formatValue(value)}
                            label={{
                                value: yAxisLabel,
                                angle: -90,
                                position: 'insideLeft',
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        {PMAX_CHANNELS.map(channel => (
                            <Bar
                                key={channel}
                                dataKey={channel}
                                fill={channelColors[channel]}
                                name={channel}
                                animationDuration={5}
                            />
                        ))}
                        <Tooltip
                            formatter={(value: number, name: string) => [formatValue(value), name]}
                            labelFormatter={formatDateLong}
                        />
                        <Legend />
                    </BarChart>
                );

            case 'stacked':
                return (
                    <BarChart data={data} stackOffset="expand">
                        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            tickFormatter={formatDateShort}
                        />
                        <YAxis
                            tickFormatter={value => `${Math.round(value * 100)}%`}
                            label={{
                                value: yAxisLabel,
                                angle: -90,
                                position: 'insideLeft',
                                style: { textAnchor: 'middle' },
                                offset: 1,
                            }}
                        />
                        {PMAX_CHANNELS.map(channel => (
                            <Bar
                                key={channel}
                                dataKey={channel}
                                stackId="a"
                                fill={channelColors[channel]}
                                name={channel}
                                animationDuration={5}
                            />
                        ))}
                        <Tooltip
                            formatter={(value: number, name: string, props: any) => {
                                const total = PMAX_CHANNELS.reduce((sum, channel) => 
                                    sum + (props.payload[channel] || 0), 0);
                                const percentage = total ? (value / total * 100).toFixed(1) : '0';
                                return [`${formatValue(value)} (${percentage}%)`, name];
                            }}
                            labelFormatter={formatDateLong}
                        />
                        <Legend />
                    </BarChart>
                );
        }
    }
};

export default PMaxSection;