// src/lib/campaign-utils.ts
import type { CampaignWithShare, ProcessedMetrics } from '@/types/metrics';

// Transform PascalCase API data to camelCase
export function transformCampaignData(data: any): CampaignWithShare {
  //console.log('Raw campaign data:', data);
  return {
    campaign: data.campaign,
    campaignId: data.campaignId,
    impr: data.impr || 0,
    clicks: data.clicks || 0,
    cost: data.cost || 0,
    conv: data.conv || 0,
    value: data.value || data.convValue || 0,
    profit: data.profit || 0,
    CTR: data.clicks > 0 ? (data.clicks / data.impr) * 100 : 0,
    CvR: data.clicks > 0 ? (data.conv / data.clicks) * 100 : 0,
    ROAS: data.cost > 0 ? data.value / data.cost : 0,
    CPA: data.conv > 0 ? data.cost / data.conv : 0,
    AOV: data.conv > 0 ? data.value / data.conv : 0,
    imprShare: data.imprShare || 0,
    lostBudget: data.lostBudget || 0,
    lostRank: data.lostRank || 0
  };
}

export function processCampaignData(rawData: Array<any>): CampaignWithShare[] {
  if (!rawData?.length) return [];

  const campaignMap = new Map<string, CampaignWithShare>();

  rawData.forEach((row) => {
    const campaign = row?.campaign || row?.Campaign;
    if (!campaign) return;

    if (!campaignMap.has(campaign)) {
      campaignMap.set(campaign, transformCampaignData(row));
    } else {
      const existing = campaignMap.get(campaign)!;
      const updated = transformCampaignData(row);
      // Merge metrics
      existing.impr += updated.impr;
      existing.clicks += updated.clicks;
      existing.cost += updated.cost;
      existing.conv += updated.conv;
      existing.value += updated.value;
      existing.profit = existing.value - existing.cost;
      // Recalculate rates
      existing.CTR = existing.clicks > 0 ? (existing.clicks / existing.impr) * 100 : 0;
      existing.CvR = existing.clicks > 0 ? (existing.conv / existing.clicks) * 100 : 0;
      existing.ROAS = existing.cost > 0 ? existing.value / existing.cost : 0;
      existing.CPA = existing.conv > 0 ? existing.cost / existing.conv : 0;
      existing.AOV = existing.conv > 0 ? existing.value / existing.conv : 0;
    }
  });

  return Array.from(campaignMap.values())
    .filter(campaign => campaign.cost > 0)
    .sort((a, b) => b.cost - a.cost);
}

export function findCampaign(campaigns: CampaignWithShare[], campaignId: string): CampaignWithShare | undefined {
  return campaigns.find(c => c.campaign === campaignId);
}

export interface Range {
  min: number
  max: number
}

export function calculateRanges(campaign: CampaignWithShare | null): {
  costRange: Range
  valueRange: Range
} {
  if (!campaign) {
    return {
      costRange: { min: 0, max: 1000 },
      valueRange: { min: 0, max: 1000 }
    }
  }

  const costMin = 0
  const costMax = Math.max(campaign.cost * 2, 1000)

  const valueMin = 0
  const valueMax = Math.max(campaign.value * 2, 1000)

  return {
    costRange: { min: costMin, max: costMax },
    valueRange: { min: valueMin, max: valueMax }
  }
}

export function getBaseMetrics(campaign: CampaignWithShare | undefined) {
  console.log('Campaign for base metrics:', campaign);
  if (!campaign) {
    return {
      cost: 0,
      value: 0, 
      conversions: 0
    }
  }
  return {
    cost: campaign.cost,
    value: campaign.value,
    conversions: campaign.conv
  }
}