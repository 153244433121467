// src/pages/trends.tsx
'use client'

import React, { useState, useMemo, useEffect } from 'react'
import { Line, LineChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Bar, BarChart, CartesianGrid } from 'recharts'
import { LineChart as LineChartIcon, BarChart as BarChartIcon } from 'lucide-react'
import { AlertCircle } from 'lucide-react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useCampaignData } from "@/contexts/campaign-data"
import { 
  StorageData, 
  CampaignWithShare,
  RawCampaignMetrics
} from '@/types/metrics'
import PMaxSection from '@/components/pmax-trends'
import { MetricKey, METRIC_CONFIGS, useMetricFormatter, calculateDerivedMetrics, getMetricColor } from '@/lib/metrics';
import { useAccounts } from '@/contexts/account-context';
import { useUser } from '@clerk/clerk-react';
import { DEBUG, PMAX_CHANNELS } from '@/lib/constants';

// Add MinimalCampaign interface since it's not in metrics.ts
interface MinimalCampaign {
  Campaign: string;
  Cost: number;
}

// Update transformMetrics to handle both data formats
const transformMetrics = (row: any) => ({
  Date: new Date(row.date || row.Date).toISOString(),
  Campaign: (row.campaign || row.Campaign || '').replace(/^["'](.+)["']$/, '$1'),
  Impressions: Number(row.Impressions || row.impr || 0),
  Clicks: Number(row.Clicks || row.clicks || 0),
  Cost: Number(row.Cost || row.cost || 0),
  Conversions: Number(row.Conversions || row.conv || 0),
  ConvValue: Number(row.ConvValue || row.value || 0),
});

export default function Trends() {
  const { data, loadingStatus, error, useSampleData } = useCampaignData();
  const { activeAccount } = useAccounts();
  const { isSignedIn } = useUser();
  const dailyData = data?.daily || [];

  // Debug logging
  useEffect(() => {
    if (DEBUG) {
      console.log('Trends: Data changed', {
        hasData: !!data,
        dailyDataLength: dailyData?.length,
        loadingStatus,
        error,
        useSampleData,
        activeAccount: activeAccount?.id,
        sampleRow: dailyData[0]
      });
    }
  }, [data, dailyData, loadingStatus, error, useSampleData, activeAccount]);

  const [selectedCampaign, setSelectedCampaign] = useState<string>('')
  const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(['Cost', 'ConvValue'])
  const [timeRange, setTimeRange] = useState<'7' | '30' | '90'>('30')
  const [chartType, setChartType] = useState<'line' | 'bar'>('line')

  // Get currency from active account
  const accountCurrency = activeAccount?.currency || '$';
  const formatMetric = useMetricFormatter(accountCurrency);

  // Format currency for campaign list
  const formatCurrency = (value: number) =>
    `${accountCurrency}${Math.round(value).toLocaleString()}`;

  // Define available metrics (we'll use this for cards and charts)
  const availableMetrics = useMemo(() =>
    Object.entries(METRIC_CONFIGS)
      .map(([key, config]) => ({
        key: key as keyof typeof METRIC_CONFIGS,
        ...config,
      }))
      .filter(
        (metric) =>
          !['ImprShare', 'LostToBudget', 'LostToRank'].includes(metric.key as string)
      ),
    []
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short'
    })
  }

  // Get the latest date from the data
  const latestDate = useMemo(() => {
    if (!dailyData?.length) return new Date();
    return new Date(Math.max(...dailyData.map(row => 
      new Date(row.date || row.Date).getTime()
    )));
  }, [dailyData]);

// Create Map of PMax campaign data for O(1) lookup
const pmaxCampaigns = useMemo(() => {
  if (!data?.pmax_perf?.length) {
    if (DEBUG) console.log('No PMax data available')
    return new Map()
  }

  // Group PMax rows by campaign
  const pmaxMap = new Map()
  data.pmax_perf.forEach(row => {
    if (!pmaxMap.has(row.Campaign)) {
      pmaxMap.set(row.Campaign, [])
    }
    pmaxMap.get(row.Campaign).push(row)
  })

  if (DEBUG) {
    console.log('PMax campaigns mapped:', {
      totalPmaxCampaigns: pmaxMap.size,
      campaigns: Array.from(pmaxMap.keys()),
      sampleData: data.pmax_perf[0]
    })
  }

  return pmaxMap
}, [data?.pmax_perf])

// Simple check - does this campaign exist in pmax_perf data?
const isPMaxCampaign = (campaign: string) => {
  if (!data?.pmax_perf?.length || !campaign) {
    if (DEBUG) console.log('No pmax data or campaign');
    return false;
  }

  const isMatch = data.pmax_perf.some(row => {
    const pmaxCampaign = row.campaign || row.Campaign;
    return pmaxCampaign === campaign;
  });

  if (DEBUG) {
    console.log('PMax campaign check:', {
      lookingFor: campaign,
      availableCampaigns: [...new Set(data.pmax_perf.map(row => 
        row.campaign || row.Campaign
      ))],
      isMatch,
      useSampleData
    });
  }

  return isMatch;
};

// Get pmax data for selected campaign
const getPMaxRows = (campaign: string) => {
  if (!data?.pmax_perf?.length || !campaign) return []
  return data.pmax_perf.filter(row => row.Campaign === campaign)
}

  // Filter and transform data
  const filteredData = useMemo(() => {
    if (!selectedCampaign) return [];

    const daysToSubtract = parseInt(timeRange, 10);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

    // Filter data based on campaign and date range
    const filtered = dailyData
      .filter((row) => {
        const rowDate = new Date(row.date || row.Date);
        const campaignName = row.campaign || row.Campaign;
        return (
          campaignName === selectedCampaign &&
          rowDate >= startDate &&
          rowDate <= latestDate
        );
      })
      .sort((a, b) => {
        const dateA = new Date(a.date || a.Date);
        const dateB = new Date(b.date || b.Date);
        return dateA.getTime() - dateB.getTime();
      });

    if (DEBUG) {
      console.log('Trends: Filtered data:', {
        campaign: selectedCampaign,
        rows: filtered.length,
        sample: filtered[0],
        useSampleData
      });
    }

    // Transform raw data to display format and calculate derived metrics
    return filtered.map((row) => {
      const metrics = transformMetrics(row);
      const { Impressions, Clicks, Cost, Conversions, ConvValue } = metrics;

      return {
        ...metrics,
        CTR: Impressions ? (Clicks / Impressions) * 100 : 0,
        CPC: Clicks ? Cost / Clicks : 0,
        CPA: Conversions ? Cost / Conversions : 0,
        ROAS: Cost ? ConvValue / Cost : 0,
        AOV: Conversions ? ConvValue / Conversions : 0,
      };
    });
  }, [dailyData, selectedCampaign, latestDate, timeRange, useSampleData]);

  // Process campaigns with raw data properties
  const campaigns = useMemo(() => {
    if (!dailyData?.length) return [] as MinimalCampaign[];

    const campaignMap = new Map<string, MinimalCampaign>();

    const daysToSubtract = parseInt(timeRange);
    const startDate = new Date(latestDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);

    dailyData.forEach(row => {
      const rowDate = new Date(row.date || row.Date);
      const campaignName = row.campaign || row.Campaign;
      
      // Only include data within the selected date range
      if (rowDate >= startDate && rowDate <= latestDate) {
        if (!campaignMap.has(campaignName)) {
          campaignMap.set(campaignName, {
            Campaign: campaignName,
            Cost: 0
          });
        }
        campaignMap.get(campaignName)!.Cost += Number(row.cost || row.Cost || 0);
      }
    });

    const sortedCampaigns = Array.from(campaignMap.values())
      .filter(campaign => campaign.Cost > 0)
      .sort((a, b) => b.Cost - a.Cost);

    if (DEBUG) {
      console.log('Trends: Campaigns processed:', {
        total: sortedCampaigns.length,
        first: sortedCampaigns[0],
        useSampleData
      });
    }

    return sortedCampaigns;
  }, [dailyData, timeRange, latestDate]);

  // Add debug logging for campaign selection changes
  useEffect(() => {
    if (DEBUG) {
      console.log('Campaign selection changed:', {
        selectedCampaign,
        campaignsAvailable: campaigns.length,
        hasFilteredData: filteredData.length > 0
      });
    }
  }, [selectedCampaign, campaigns.length, filteredData.length]);

  // Calculate date range summary
  const dateRangeSummary = useMemo(() => {
    if (!filteredData.length) return 'No dates available'

    const dates = filteredData.map((d) => new Date(d.Date))
    const startDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const endDate = new Date(Math.max(...dates.map(d => d.getTime())))

    return `${startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })} to ${endDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })}`
  }, [filteredData]);

  // Update totals calculation
  const totals = useMemo(() => {
    if (!filteredData?.length) {
      if (DEBUG) console.log('No filtered data for totals');
      return {
        Impressions: 0,
        Clicks: 0,
        Cost: 0,
        Conversions: 0,
        ConvValue: 0,
        CTR: 0,
        CPC: 0,
        CPA: 0,
        ROAS: 0,
        AOV: 0,
      };
    }

    return filteredData.reduce((acc, row) => ({
      // Base metrics (already transformed in filteredData)
      Impressions: acc.Impressions + row.Impressions,
      Clicks: acc.Clicks + row.Clicks,
      Cost: acc.Cost + row.Cost,
      Conversions: acc.Conversions + row.Conversions,
      ConvValue: acc.ConvValue + row.ConvValue,

      // Derived metrics calculated from totals
      CTR: acc.Impressions + row.Impressions > 0
        ? ((acc.Clicks + row.Clicks) / (acc.Impressions + row.Impressions)) * 100
        : 0,
      CPC: acc.Clicks + row.Clicks > 0
        ? (acc.Cost + row.Cost) / (acc.Clicks + row.Clicks)
        : 0,
      CPA: acc.Conversions + row.Conversions > 0
        ? (acc.Cost + row.Cost) / (acc.Conversions + row.Conversions)
        : 0,
      ROAS: acc.Cost + row.Cost > 0
        ? (acc.ConvValue + row.ConvValue) / (acc.Cost + row.Cost)
        : 0,
      AOV: acc.Conversions + row.Conversions > 0
        ? (acc.ConvValue + row.ConvValue) / (acc.Conversions + row.Conversions)
        : 0,
    }), {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
      CTR: 0,
      CPC: 0,
      CPA: 0,
      ROAS: 0,
      AOV: 0,
    });
  }, [filteredData]);

  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial'
            ? "Loading your campaign data (this may take a minute or two)..."
            : "Loading..."}
        </div>
      </div>
    );
  }

  if (!dailyData?.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No campaign data available. {useSampleData ?
            'Sample data may be incomplete.' :
            'Please check your data source in Settings.'}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    );
  }

  // Update toggleMetric type
  const toggleMetric = (metricKey: MetricKey) => {
    setSelectedMetrics(current => {
      if (current.includes(metricKey)) {
        return current.filter(m => m !== metricKey)
      }
      if (current.length < 2) {
        return [...current, metricKey]
      }
      return [current[1], metricKey]
    })
  }

  // Debug logging for campaigns
  useEffect(() => {
    console.log('Trends: Campaigns calculated', {
      campaignsLength: campaigns.length,
      firstCampaign: campaigns[0]?.Campaign,
      selectedCampaign
    });
  }, [campaigns, selectedCampaign]);

  useEffect(() => {
    if (campaigns.length > 0) {
      // Always select highest spend campaign when campaigns list changes
      const highestSpendCampaign = campaigns[0]; // campaigns are already sorted by Cost
      if (DEBUG) {
        console.log('Auto-selecting highest cost campaign:', {
          campaign: highestSpendCampaign.Campaign,
          cost: highestSpendCampaign.Cost,
          totalCampaigns: campaigns.length
        });
      }
      setSelectedCampaign(highestSpendCampaign.Campaign);
    } else {
      setSelectedCampaign('');
    }
  }, [campaigns]); // campaigns will update when data source changes

  const campaignsAvailable = useMemo(() => {
    if (!dailyData?.length) return 0
    return new Set(dailyData.map(row => row.campaign)).size
  }, [dailyData])

  const hasFilteredData = useMemo(() => {
    const has = selectedCampaign && filteredData.length > 0
    if (DEBUG) {
      console.log('Filtered data status:', {
        selectedCampaign,
        filteredDataLength: filteredData.length,
        hasFilteredData: has
      })
    }
    return has
  }, [selectedCampaign, filteredData])

  if (DEBUG) {
    console.log('Trends: Campaign state:', {
      campaignsLength: campaignsAvailable,
      selectedCampaign,
      hasPMaxData: !!data?.pmax_perf?.length,
      pmaxCampaignsCount: pmaxCampaigns.size
    })
  }

  const isPmax = selectedCampaign ? isPMaxCampaign(selectedCampaign) : false
  const pmaxRows = selectedCampaign ? getPMaxRows(selectedCampaign) : []

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex items-center justify-between">
        <Select
          value={selectedCampaign}
          onValueChange={setSelectedCampaign}
        >
          <SelectTrigger className="h-10 w-[500px]">
            <SelectValue placeholder="Select a campaign" />
          </SelectTrigger>
          <SelectContent>
            {campaigns.map(({ Campaign, Cost }) => (
              <SelectItem
                key={Campaign}
                value={Campaign}
                className="flex items-center justify-between py-2 pr-2"
              >
                <span className="truncate mr-4">
                  {Campaign}
                </span>
                <span className="text-sm font-medium text-muted-foreground">
                  {formatCurrency(Cost)}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex flex-col items-end gap-1">
          <span className="text-xs text-muted-foreground -mb-1">
            {dateRangeSummary}
          </span>
          <Tabs
            defaultValue="30"
            value={timeRange}
            onValueChange={(value) => setTimeRange(value as '7' | '30' | '90')}
          >
            <TabsList className="bg-transparent gap-2">
              <TabsTrigger value="7" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                7 Days
              </TabsTrigger>
              <TabsTrigger value="30" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                30 Days
              </TabsTrigger>
              <TabsTrigger value="90" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
                90 Days
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* Metric Cards */}
      <div className="grid grid-cols-10 gap-2">
        {availableMetrics.map(metric => (
          <Card
            key={metric.key}
            onClick={() => toggleMetric(metric.key)}
            className={`cursor-pointer transition-colors hover:bg-accent ${selectedMetrics.includes(metric.key) ? 'ring-2 ring-primary' : ''
              }`}
          >
            <CardHeader className="pb-1 pt-2 px-3 space-y-0">
              <CardTitle className="text-xs font-medium">
                {useSampleData && '(Sample) '}{metric.label}
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-2 px-3">
              <div className="text-lg font-bold">
                {formatMetric(metric.key, totals[metric.key] || 0)}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Main Chart */}
      <div className="space-y-2 mb-8">
        <div className="flex items-center justify-between">
          <CardTitle>
            {useSampleData && '(Sample) '}Campaign Performance Metrics
          </CardTitle>
          <Tabs value={chartType} onValueChange={(value) => setChartType(value as 'line' | 'bar')}>
            <TabsList className="grid w-fit grid-cols-2">
              <TabsTrigger value="line" className="px-4">
                <LineChartIcon className="h-4 w-4 mr-2" />
                Line
              </TabsTrigger>
              <TabsTrigger value="bar" className="px-4">
                <BarChartIcon className="h-4 w-4 mr-2" />
                Bar
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <Card>
          <CardContent className="pt-6">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                {chartType === 'line' ? (
                  <LineChart data={filteredData}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: METRIC_CONFIGS[metricKey].label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey={metricKey}
                            stroke={getMetricColor(index)}
                            yAxisId={index}
                            name={METRIC_CONFIGS[metricKey].label}
                            dot={false}
                            strokeWidth={2}
                            animationDuration={5}
                          />
                        </React.Fragment>
                      )
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </LineChart>
                ) : (
                  <BarChart data={filteredData} barSize={20}>
                    <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Date"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tickFormatter={formatDate}
                    />
                    {selectedMetrics.map((metricKey, index) => {
                      const metric = METRIC_CONFIGS[metricKey]
                      if (!metric) return null

                      return (
                        <React.Fragment key={metricKey}>
                          <YAxis
                            yAxisId={index}
                            orientation={index === 0 ? "left" : "right"}
                            tickFormatter={(value) => formatMetric(metricKey, value)}
                            label={{
                              value: METRIC_CONFIGS[metricKey].label,
                              angle: -90,
                              position: 'insideLeft',
                              style: { textAnchor: 'middle' }
                            }}
                          />
                          <Bar
                            dataKey={metricKey}
                            fill={useSampleData ? "#94a3b8" : getMetricColor(index)}
                            yAxisId={index}
                            name={METRIC_CONFIGS[metricKey].label}
                            radius={[4, 4, 0, 0]}
                            animationDuration={5}
                          />
                        </React.Fragment>
                      )
                    })}
                    <Tooltip
                      formatter={(value: number, name: string) => {
                        const metricKey = selectedMetrics.find(key =>
                          METRIC_CONFIGS[key].label === name
                        )
                        return [
                          metricKey ? formatMetric(metricKey, value) : value,
                          name
                        ]
                      }}
                      labelFormatter={(label: string) => new Date(label).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })}
                    />
                    <Legend />
                  </BarChart>
                )}
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* PMax Section */}
      <div className="space-y-2 mt-8">
        {isPmax && data?.pmax_perf ? (
          <PMaxSection
            pmaxData={pmaxRows}
            selectedCampaign={selectedCampaign}
            timeRange={timeRange}
            latestDate={latestDate}
            currency={accountCurrency}
          />
        ) : (
          <Card className="bg-muted/50">
            <CardContent className="flex flex-col items-center justify-center py-12 text-center">
              <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
              <h3 className="text-lg font-medium mb-2">Performance Channel Analysis</h3>
              <p className="text-muted-foreground">
                This analysis is only available for Performance Max campaigns.
                Select a PMax campaign to view detailed channel performance.
              </p>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  )
}