import React, { useRef, useEffect, useState, useMemo } from 'react';

type ChartStage = 'profit-line' | 'volume-curve' | 'profit-zero' | 'profit-breakeven' | 'profit-final' | 'profit-target';

interface ScrollySection {
  id: string;
  title: string;
  content: string;
  stage: ChartStage;
}

const Chart = ({ stage = 'profit-line', width = 800, height = 600 }: { 
  stage: ChartStage;
  width?: number;
  height?: number;
}) => {
  const maxY = height - 60;
  const maxX = width - 60;

  const toX = (x: number) => (x / 100) * maxX;
  const toY = (y: number) => maxY - (y / 100) * maxY;

  // Sigmoid function for volume curve
  const sigmoid = (x: number) => {
    return 1 / (1 + Math.exp(-(x - 50) / 10));
  };

  // Generate S-curve points
  const volumeCurvePoints = Array.from({length: 100}, (_, i) => {
    const x = i;
    const y = sigmoid(x) * 100;
    return `${toX(x)},${toY(y)}`;
  }).join(' L ');

  // Initial small profit curve
  const initialProfitPath = `M 0 ${maxY}
    C ${maxX * 0.1} ${maxY * 0.9},
      ${maxX * 0.15} ${maxY * 0.85},
      ${maxX * 0.2} ${maxY * 0.8}`;

  // Final profit curve that matches blog image
  const finalProfitPath = `M 0 ${maxY}
    C ${maxX * 0.25} ${maxY * 0.7},
      ${maxX * 0.35} ${maxY * 0.2},
      ${maxX * 0.45} ${maxY * 0.15}
    C ${maxX * 0.55} ${maxY * 0.15},
      ${maxX * 0.65} ${maxY * 0.2},
      ${maxX * 0.8} ${maxY * 0.6}
    C ${maxX * 0.9} ${maxY * 0.9},
      ${maxX * 0.95} ${maxY * 1.1},
      ${maxX} ${maxY * 1.3}`;

  // For the max profit stage, we need to add markers for A, B, and breakeven points
  const pointA = { x: maxX * 0.39, y: maxY * 0.3 }; // 39% of width
  const pointB = { x: maxX * 0.62, y: maxY * 0.3 }; // 62% of width
  const breakeven = { x: maxX * 0.85, y: maxY }; // 85% of width

  return (
    <svg 
      viewBox={`-60 -40 ${width + 120} ${height + 80}`}
      className="w-full h-full"
    >
      {/* Base grid and axes */}
      <defs>
        <pattern id="grid" width={toX(20)} height={toY(20)} patternUnits="userSpaceOnUse">
          <path d={`M ${toX(20)} 0 L 0 0 0 ${toY(20)}`} fill="none" stroke="currentColor" strokeWidth="0.5" strokeOpacity="0.1"/>
        </pattern>
      </defs>
      <rect width={maxX} height={maxY} fill="url(#grid)" />

      <line x1="0" y1={maxY} x2="0" y2="0" stroke="currentColor" strokeWidth="3" />
      <line x1="0" y1={maxY} x2={maxX} y2={maxY} stroke="currentColor" strokeWidth="3" />

      {stage === 'profit-line' && (
        <>
          <text x="-40" y="20" className="text-base font-medium" fill="currentColor">$150</text>
          <text x="-20" y={maxY + 10} className="text-base font-medium" fill="currentColor">$0</text>
          <text x={maxX - 40} y={maxY + 40} className="text-base font-medium" fill="currentColor">$150</text>
          
          <text x={-45} y={maxY/2} className="text-lg font-semibold" transform={`rotate(-90 ${-45} ${maxY/2})`} fill="currentColor">
            Average Profit per Lead
          </text>
          <text x={maxX/2} y={maxY + 55} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">
            CPA
          </text>

          <line 
            x1="0" 
            y1={toY(100)} 
            x2={toX(100)} 
            y2={toY(0)} 
            stroke="#2563eb" 
            strokeWidth="4"
          />
        </>
      )}

      {stage === 'volume-curve' && (
        <>
          <text x={-45} y={maxY/2} className="text-lg font-semibold" transform={`rotate(-90 ${-45} ${maxY/2})`} fill="currentColor">
            Lead Volume
          </text>
          <text x={maxX/2} y={maxY + 55} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">
            CPA
          </text>

          <path
            d={`M ${toX(0)} ${maxY} L ${volumeCurvePoints}`}
            fill="none"
            stroke="#2563eb"
            strokeWidth="4"
          />
        </>
      )}

      {stage === 'profit-zero' && (
        <>
          <text x={-45} y={maxY/2} className="text-lg font-semibold" transform={`rotate(-90 ${-45} ${maxY/2})`} fill="currentColor">
            Total
          </text>
          <text x={maxX/2} y={maxY + 55} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">
            CPA
          </text>

          <path
            d={initialProfitPath}
            fill="none"
            stroke="#ef4444"
            strokeWidth="4"
          />
        </>
      )}

      {stage === 'profit-breakeven' && (
        <>
          <text x={-45} y={maxY/2} className="text-lg font-semibold" transform={`rotate(-90 ${-45} ${maxY/2})`} fill="currentColor">
            Total
          </text>
          <text x={maxX/2} y={maxY + 55} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">
            CPA
          </text>

          {/* Breakeven vertical line */}
          <line 
            x1={toX(80)} 
            y1="0" 
            x2={toX(80)} 
            y2={maxY} 
            stroke="#dc2626" 
            strokeWidth="2" 
            strokeDasharray="6,4"
          />
          
          {/* Downward sloping line through breakeven point */}
          <line 
            x1={toX(140)} 
            y1={toY(20)} 
            x2={toX(160)} 
            y2={toY(80)} 
            stroke="ef4444" 
            strokeWidth="4"
            className="transition-all duration-500 ease-in-out"
          />
          
          <text x={toX(82)} y={maxY/2} className="text-base fill-red-600" dominantBaseline="middle">
            Breakeven CPA
          </text>
          
          <text x={toX(82)} y={maxY/2 + 30} className="text-sm fill-red-600" dominantBaseline="middle">
            (Account Average)
          </text>
        </>
      )}

      {(stage === 'profit-final' || stage === 'profit-target') && (
        <>
          <text x={-45} y={maxY/2} className="text-lg font-semibold" transform={`rotate(-90 ${-45} ${maxY/2})`} fill="currentColor">
            Total
          </text>
          <text x={maxX/2} y={maxY + 55} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">
            CPA
          </text>

          {/* Base profit curve */}
          <path
            d={finalProfitPath}
            fill="none"
            stroke="#ef4444"
            strokeWidth="4"
          />

          {stage === 'profit-target' && (
            <>
              {/* Max profit line */}
              <line
                x1={pointA.x}
                y1={pointA.y - 45}
                x2={pointB.x}
                y2={pointB.y - 45}
                stroke="#22c55e"
                strokeWidth="2"
                strokeDasharray="6,4"
              />

              {/* Vertical dotted lines */}
              <line
                x1={pointA.x}
                y1={pointA.y - 45}
                x2={pointA.x}
                y2={maxY - 45}
                stroke="#666"
                strokeWidth="1"
                strokeDasharray="4,4"
              />
              <line
                x1={pointB.x}
                y1={pointB.y - 45}
                x2={pointB.x}
                y2={maxY - 45}
                stroke="#666"
                strokeWidth="1"
                strokeDasharray="4,4"
              />

              {/* Point markers */}
              <circle cx={pointA.x} cy={pointA.y - 45} r="4" fill="#ef4444" />
              <circle cx={pointB.x} cy={pointB.y - 45} r="4" fill="#ef4444" />
              <circle cx={breakeven.x + 50} cy={breakeven.y} r="4" fill="#ef4444" />

              {/* Labels */}
              <text x={pointA.x} y={maxY + 30} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">$60</text>
              <text x={pointB.x} y={maxY + 30} className="text-lg font-semibold" fill="currentColor" textAnchor="middle">$110</text>
              
              <text x={pointA.x - 10} y={pointA.y - 45} className="text-base fill-red-600">A</text>
              <text x={pointB.x - 10} y={pointB.y - 45} className="text-base fill-red-600">B</text>
              <text x={breakeven.x + 50} y={breakeven.y - 10} className="text-base fill-red-600">Breakeven</text>

              {/* Shaded area */}
              <path
                d={`M ${pointA.x} ${pointA.y - 45}
                    L ${pointA.x} ${maxY}
                    L ${pointB.x} ${maxY}
                    L ${pointB.x} ${pointB.y - 45}
                    Z`}
                fill="#22c55e"
                fillOpacity="0.1"
              />
            </>
          )}
        </>
      )}
    </svg>
  );
};

const ScrollytellingStory = () => {
  const [activeSection, setActiveSection] = useState<string>('profit-per-lead');
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const sections: ScrollySection[] = [
    {
      id: 'profit-per-lead',
      title: "Profit Per Lead",
      content: "Assume our breakeven point is $150 per lead. If we could get leads for $0, we'd make $150 profit per lead. At $150 per lead, our profit would be $0. This gives us our first line - the Average Profit per Lead.",
      stage: 'profit-line'
    },
    {
      id: 'conversion-volume',
      title: "Conversion Volume",
      content: "For each keyword, the more we're willing to pay per lead, the more leads we can get - up to a point. There are only so many people searching each day. This gives us our S-shaped volume curve.",
      stage: 'volume-curve'
    },
    {
      id: 'no-leads-no-profit',
      title: "No Leads, No Profit",
      content: "Now let's look at total profit. When CPA is near zero, we have maximum profit per sale but minimal volume. As CPA increases slightly, our total profit grows because we're still making good margins while increasing our conversion volume.",
      stage: 'profit-zero'
    },
    {
      id: 'breakeven-cpa',
      title: "Breakeven CPA = No Profit",
      content: "We first need to understand how much that new customer is worth to your business. What's the AVERAGE cost per lead that causes you to breakeven? Anything above this is losing money, and anything below is profitable.",
      stage: 'profit-breakeven'
    },
    {
      id: 'profit-curve',
      title: "The Profit Curve",
      content: "Combining these factors gives us our Profit Curve. We make $0 at both extremes - when CPA is $0 (no leads) and when CPA equals our breakeven ($150). The sweet spot lies somewhere in between.",
      stage: 'profit-final'
    },
    {
      id: 'maximum-profit',
      title: "Maximum Profit",
      content: "Rather than chasing a single 'maximum profit' point, we can identify a profitable zone between points A ($60) and B ($110). While both points generate the same total profit, point B delivers more leads - providing more opportunities for optimization and growth.",
      stage: 'profit-target'
    }
  ];

  useEffect(() => {
    const observers: IntersectionObserver[] = [];
    
    sections.forEach(section => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setActiveSection(section.id);
            }
          });
        },
        {
          threshold: [0.1, 0.5, 0.9],
          rootMargin: '-20% 0px -20% 0px'
        }
      );

      if (sectionRefs.current[section.id]) {
        observer.observe(sectionRefs.current[section.id]!);
      }
      observers.push(observer);
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, []);

  const activeStage = useMemo(() => {
    const section = sections.find(s => s.id === activeSection);
    return section?.stage || 'profit-line';
  }, [activeSection]);

  return (
    <div className="flex min-h-screen bg-background">
      <div className="w-[70%] sticky top-0 h-screen flex items-center justify-center">
        <div className="w-full max-w-5xl p-12">
          <Chart key={activeStage} stage={activeStage} width={800} height={600} />
        </div>
      </div>
      
      <div className="w-[30%] border-l">
        {sections.map(section => (
          <div
            key={section.id}
            ref={el => {
              sectionRefs.current[section.id] = el;
            }}
            className="min-h-screen flex items-center"
            id={section.id}
          >
            <div className="p-8">
              <h2 className="text-3xl font-bold mb-6 text-foreground">{section.title}</h2>
              <p className="text-xl leading-relaxed text-muted-foreground">{section.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollytellingStory;