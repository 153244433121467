import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from "@/components/ui/alert";
import { LeadGenPanel } from './lead-gen-panel';
import type { 
  CampaignMetrics,
  OptimalZone,
  ProfitDataPoint,
  BusinessMode,
  MarginalMetrics,
  CampaignWithShare
} from '@/types/metrics'

interface PanelProps {
  optimalZone: OptimalZone
  currentMetrics: {
    cost: number;
    conv: number;
    value: number;
    profit: number;
  }
  profitData: ProfitDataPoint[]
  selectedCurrency: string
  currentCost: number
  businessMode: BusinessMode
  breakEvenCpa: number
  cogsPercentage: number
  campaign: CampaignWithShare
}

export function ProfitAnalysisPanel({
  optimalZone,
  currentMetrics,
  profitData,
  selectedCurrency,
  currentCost,
  businessMode,
  breakEvenCpa,
  cogsPercentage,
  campaign
}: PanelProps) {
  // Transform metrics for lead gen panel
  const transformedMetrics = useMemo(() => ({
    cost: currentMetrics.cost,
    conv: currentMetrics.conv,
    value: currentMetrics.value,
    profit: currentMetrics.profit
  }), [currentMetrics])

  if (businessMode === 'leadgen') {
    return (
      <LeadGenPanel
        currentMetrics={transformedMetrics}
        campaign={campaign}
        curvePoints={profitData}  // Pass profit data as curve points
        breakEvenCpa={breakEvenCpa}
        selectedCurrency={selectedCurrency}
        optimalZone={optimalZone}  // Pass optimal zone data
      />
    )
  }

  const incrementalROAS = (() => {
    const currentIndex = profitData.findIndex(p => p.cost >= currentCost);
    if (currentIndex <= 0 || currentIndex >= profitData.length) return "N/A";
    const current = profitData[currentIndex];
    const prev = profitData[currentIndex - 1];
    const incrementalValue = current.value - prev.value;
    const incrementalCost = current.cost - prev.cost;
    return incrementalCost > 0
      ? (incrementalValue / incrementalCost).toFixed(2) + 'x'
      : "N/A";
  })();

  // Calculate ROAS from simplified metrics
  const ROAS = currentMetrics.cost > 0 ? currentMetrics.value / currentMetrics.cost : 0;

  // Find optimal point and safely access ROAS
  const optimalStartRoas = profitData.find(p => Math.abs(p.cost - optimalZone.start) < 1)?.roas;
  const optimalEndRoas = profitData.find(p => Math.abs(p.cost - optimalZone.end) < 1)?.roas;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-6">
        <div>
          <h3 className="font-semibold mb-3 text-lg">Optimal Profit Range</h3>
          <table className="w-full">
            <tbody className="divide-y divide-border">
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Maximum Profit</div>
                  <div className="font-medium text-green-500">
                    {selectedCurrency}{Math.round(optimalZone.maxProfit).toLocaleString()}
                  </div>
                </td>
                <td className="py-2">
                  <div className="text-sm">Current Profit</div>
                  <div className={`font-medium ${
                    (currentMetrics.value * (1 - (cogsPercentage || 0)/100) - currentMetrics.cost) < 0 
                      ? 'text-red-500' 
                      : ''
                  }`}>
                    {selectedCurrency}
                    {Math.round(
                      currentMetrics.value * (1 - (cogsPercentage || 0)/100) - currentMetrics.cost
                    ).toLocaleString()}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Range Start</div>
                  <div className="font-medium">{selectedCurrency}{Math.round(optimalZone.start).toLocaleString()}</div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Range End</div>
                  <div className="font-medium">{selectedCurrency}{Math.round(optimalZone.end).toLocaleString()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 className="font-semibold mb-3 text-lg">ROAS Analysis</h3>
          <table className="w-full">
            <tbody className="divide-y divide-border">
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Marginal ROAS</div>
                  <div className="font-medium">
                    {incrementalROAS}
                  </div>
                </td>
                <td className="py-2">
                  <div className="text-sm">Current ROAS</div>
                  <div className="font-medium">{ROAS.toFixed(2)}x</div>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Lower Bound ROAS</div>
                  <div className="font-medium">
                    {optimalStartRoas ? `${optimalStartRoas.toFixed(2)}x` : 'N/A'}
                  </div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Upper Bound ROAS</div>
                  <div className="font-medium">
                    {optimalEndRoas ? `${optimalEndRoas.toFixed(2)}x` : 'N/A'}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 className="font-semibold mb-3 text-lg">Current Status</h3>
          <div className="space-y-3">
            {currentCost < optimalZone.start ? (
              <Alert variant="warning">
                <AlertDescription className="text-lg">
                  Underspending by {selectedCurrency}{Math.round(optimalZone.start - currentCost).toLocaleString()}.
                  Consider increasing budget to reach optimal zone.
                </AlertDescription>
              </Alert>
            ) : currentCost > optimalZone.end ? (
              <Alert variant="destructive">
                <AlertDescription className="text-lg">
                  Overspending by {selectedCurrency}{Math.round(currentCost - optimalZone.end).toLocaleString()}.
                  Consider reducing spend to improve profitability.
                </AlertDescription>
              </Alert>
            ) : (
              <Alert variant="success">
                <AlertDescription className="text-lg">
                  Currently within optimal profit zone.
                  Monitor performance to maintain position.
                </AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}