export const LEVEL_1_CODE = `function main() {
  Logger.log("Hello, Google Ads Scripts!");
}`;

export const LEVEL_2_CODE = `function main() {
  const account = AdsApp.currentAccount();
  Logger.log("Account Name: " + account.getName());
  Logger.log("Account ID: " + account.getCustomerId());
}`;

export const LEVEL_3_CODE = `function main() {
  const stats = AdsApp.currentAccount().getStatsFor("YESTERDAY");
  Logger.log("Impressions: " + stats.getImpressions());
  Logger.log("Clicks: " + stats.getClicks());
  Logger.log("CTR: " + stats.getCtr());
}`;

export const LEVEL_4_CODE = `function main() {
  const stats = AdsApp.currentAccount().getStatsFor("LAST_7_DAYS");
  Logger.log("Impressions: " + stats.getImpressions());
  Logger.log("Clicks: " + stats.getClicks());
  Logger.log("CTR: " + stats.getCtr());
  Logger.log("Cost: " + stats.getCost());
  Logger.log("Conv: " + stats.getConversions());
}`;

export const LEVEL_5_CODE = `function main() {
  const newSheet = SpreadsheetApp.create("Google Ads Data " + new Date().toLocaleDateString());
  const sheet = newSheet.getActiveSheet();
  const stats = AdsApp.currentAccount().getStatsFor("LAST_30_DAYS");
  const headers = ["Metric", "Value"];
  const data = [
    ["Impressions", stats.getImpressions()],
    ["Clicks", stats.getClicks()],
    ["CTR", stats.getCtr()],
    ["Cost", stats.getCost()],
    ["Conversions", stats.getConversions()],
    ["Cost per Conversion", stats.getCost() / stats.getConversions()]
  ];
  sheet.getRange(1, 1, 1, headers.length).setValues([headers]);
  sheet.getRange(2, 1, data.length, data[0].length).setValues(data);
  Logger.log("Sheet URL: " + newSheet.getUrl());
}`;

export const LEVEL_6_CODE = `function main() {
  const newSheet = SpreadsheetApp.create("Google Ads Search Data " + new Date().toLocaleDateString());
  
  // Get the first sheet (default sheet) - this works regardless of language settings
  const defaultSheet = newSheet.getSheets()[0];
  
  const sheets = {
    'Campaigns': { headers: ['Campaign', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Ad Groups': { headers: ['Campaign', 'Ad Group', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Keywords': { headers: ['Campaign', 'Ad Group', 'Keyword', 'Match Type', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] }
  };
  
  // Create all new sheets first
  Object.keys(sheets).forEach(name => {
    sheets[name].sheet = newSheet.insertSheet(name);
    sheets[name].sheet.getRange(1, 1, 1, sheets[name].headers.length).setValues([sheets[name].headers]);
  });
  
  // Delete the default sheet only after creating new ones
  // This prevents errors in case it's the only sheet
  if (defaultSheet) {
    newSheet.deleteSheet(defaultSheet);
  }
  
  const queries = {
    'Campaigns': "SELECT campaign.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM campaign WHERE campaign.advertising_channel_type = 'SEARCH' AND segments.date DURING LAST_30_DAYS",
    'Ad Groups': "SELECT campaign.name, ad_group.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM ad_group WHERE campaign.advertising_channel_type = 'SEARCH' AND segments.date DURING LAST_30_DAYS",
    'Keywords': "SELECT campaign.name, ad_group.name, ad_group_criterion.keyword.text, ad_group_criterion.keyword.match_type, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM keyword_view WHERE campaign.advertising_channel_type = 'SEARCH' AND segments.date DURING LAST_30_DAYS"
  };
  
  Object.entries(queries).forEach(([type, query]) => {
    const rows = [];
    const result = AdsApp.search(query);
    
    while (result.hasNext()) {
      const row = result.next();
      const metrics = row.metrics;
      const baseMetrics = [
        Number(metrics.impressions) || 0,
        Number(metrics.clicks) || 0,
        Number(metrics.ctr) || 0,
        (Number(metrics.costMicros) || 0) / 1000000,
        Number(metrics.conversions) || 0,
        Number(metrics.conversions) ? (Number(metrics.costMicros) / 1000000) / Number(metrics.conversions) : 0
      ];
      rows.push(type === 'Campaigns' ? 
        [row.campaign.name, ...baseMetrics] :
        type === 'Ad Groups' ?
        [row.campaign.name, row.adGroup.name, ...baseMetrics] :
        [row.campaign.name, row.adGroup.name, row.adGroupCriterion.keyword.text, row.adGroupCriterion.keyword.matchType, ...baseMetrics]
      );
    }
    const sheet = sheets[type].sheet;
    if (rows.length) {
      sheet.getRange(2, 1, rows.length, rows[0].length).setValues(rows);
    } else {
      sheet.getRange(2, 1).setValue("No data found for SEARCH campaigns in this report.");
    }
  });
  
  Logger.log("Search campaign report created! URL: " + newSheet.getUrl());
}`;

export const LEVEL_7_CODE = `function main() {
  const newSheet = SpreadsheetApp.create("Google Ads Data " + new Date().toLocaleDateString());
  const sheets = {
    'Campaigns': { headers: ['Campaign', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Ad Groups': { headers: ['Campaign', 'Ad Group', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Keywords': { headers: ['Campaign', 'Ad Group', 'Keyword', 'Match Type', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] }
  };

  Object.entries(sheets).forEach(([name, config]) => {
    const sheet = newSheet.insertSheet(name);
    sheet.clear();
    sheet.getRange(1, 1, 1, config.headers.length).setValues([config.headers]);
    config.sheet = sheet;
  });

  const queries = {
    'Campaigns': "SELECT campaign.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM campaign WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC",
    'Ad Groups': "SELECT campaign.name, ad_group.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM ad_group WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC",
    'Keywords': "SELECT campaign.name, ad_group.name, ad_group_criterion.keyword.text, ad_group_criterion.keyword.match_type, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM keyword_view WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC"
  };

  Object.entries(queries).forEach(([type, query]) => {
    const rows = [];
    const result = AdsApp.search(query);
    
    while (result.hasNext()) {
      const row = result.next();
      const metrics = row.metrics;
      const imp = Number(metrics.impressions);
      const clicks = Number(metrics.clicks);
      const cost = Number(metrics.costMicros) / 1000000;
      const conv = Number(metrics.conversions);
      
      const baseMetrics = [
        imp,
        clicks,
        clicks / imp * 100 || 0,
        cost,
        conv,
        cost / (conv || 1)
      ];

      rows.push(type === 'Campaigns' ? 
        [row.campaign.name, ...baseMetrics] :
        type === 'Ad Groups' ?
        [row.campaign.name, row.adGroup.name, ...baseMetrics] :
        [row.campaign.name, row.adGroup.name, row.adGroupCriterion.keyword.text, row.adGroupCriterion.keyword.matchType, ...baseMetrics]
      );
    }

    if (rows.length) {
      const sheet = sheets[type].sheet;
      sheet.getRange(2, 1, rows.length, rows[0].length).setValues(rows);
      sheet.autoResizeColumns(1, sheets[type].headers.length);
    }
  });

  Logger.log("Filtered report created! URL: " + newSheet.getUrl());
}`;

export const LEVEL_8_CODE = `function main() {
  // Replace this URL with your Google Sheet URL
  const SHEET_URL = '';

  const sheet = SpreadsheetApp.openByUrl(SHEET_URL);
  
  const sheets = {
    'Campaigns': { headers: ['Campaign', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Ad Groups': { headers: ['Campaign', 'Ad Group', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] },
    'Keywords': { headers: ['Campaign', 'Ad Group', 'Keyword', 'Match Type', 'Impressions', 'Clicks', 'CTR', 'Cost', 'Conversions', 'Cost/Conv'] }
  };

  // Setup sheets and delete default
  Object.keys(sheets).forEach(name => {
    sheets[name].sheet = sheet.getSheetByName(name) || sheet.insertSheet(name);
    sheets[name].sheet.clear();
    sheets[name].sheet.getRange(1, 1, 1, sheets[name].headers.length).setValues([sheets[name].headers]);
    sheets[name].sheet.getRange("1:1").setFontWeight("bold");
    sheets[name].sheet.setFrozenRows(1);
  });

  // GAQL queries
  const queries = {
    'Campaigns': "SELECT campaign.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM campaign WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC",
    'Ad Groups': "SELECT campaign.name, ad_group.name, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM ad_group WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC",
    'Keywords': "SELECT campaign.name, ad_group.name, ad_group_criterion.keyword.text, ad_group_criterion.keyword.match_type, metrics.impressions, metrics.clicks, metrics.ctr, metrics.cost_micros, metrics.conversions FROM keyword_view WHERE segments.date DURING LAST_30_DAYS AND metrics.cost_micros > 0 ORDER BY metrics.cost_micros DESC"
  };

  // Execute queries and write data
  Object.keys(queries).forEach(type => {
    const rows = [];
    const query = AdsApp.search(queries[type]);
    
    while (query.hasNext()) {
      const row = query.next();
      
      const baseMetrics = [
        Number(row.metrics.impressions),
        Number(row.metrics.clicks),
        Number(row.metrics.clicks) / Number(row.metrics.impressions) * 100 || 0,
        Number(row.metrics.costMicros) / 1000000,
        Number(row.metrics.conversions),
        (Number(row.metrics.costMicros) / 1000000) / (Number(row.metrics.conversions) || 1)
      ];

      if (type === 'Campaigns') {
        rows.push([row.campaign.name, ...baseMetrics]);
      } else if (type === 'Ad Groups') {
        rows.push([row.campaign.name, row.adGroup.name, ...baseMetrics]);
      } else if (type === 'Keywords') {
        rows.push([
          row.campaign.name,
          row.adGroup.name,
          row.adGroupCriterion.keyword.text,
          row.adGroupCriterion.keyword.matchType,
          ...baseMetrics
        ]);
      }
    }

    if (rows.length > 0) {
      const range = sheets[type].sheet.getRange(2, 1, rows.length, rows[0].length);
      range.setValues(rows);
      sheets[type].sheet.autoResizeColumns(1, sheets[type].headers.length);
    }
  });

  Logger.log("Data updated in sheet: " + sheet.getUrl());
}`;

export const LEVEL_9_CODE = `const YOUR_EMAIL = ''; // enter your email address here between the single quotes

function main() {
  const campaigns = AdsApp.campaigns()
    .withCondition('Status = ENABLED')
    .withCondition('Impressions = 0')
    .forDateRange('YESTERDAY')
    .get();
    
  if (campaigns.totalNumEntities() === 0) {
    Logger.log('All campaigns received impressions yesterday - no email sent');
    return;
  }
  
  const problemCampaigns = [];
  while (campaigns.hasNext()) {
    const campaign = campaigns.next();
    problemCampaigns.push(campaign.getName());
  }
  
  const subject = 'Google Ads Alert: Campaigns With Zero Impressions';
  const htmlBody = '<p>The following campaigns had zero impressions yesterday:</p>' +
                  '<ul><li>' + problemCampaigns.join('</li><li>') + '</li></ul>' +
                  '<p>Please check for issues with:</p>' +
                  '<ul>' +
                  '<li>Ad approval status</li>' +
                  '<li>Budget restrictions</li>' +
                  '<li>Targeting settings</li>' +
                  '<li>Billing issues</li>' +
                  '</ul>';
               
  MailApp.sendEmail({
    to: YOUR_EMAIL,
    subject: subject,
    htmlBody: htmlBody,
    body: 'The following campaigns had zero impressions yesterday:\n\n' +
          problemCampaigns.join('\n') +
          '\n\nPlease check for issues with:' +
          '\n- Ad approval status' +
          '\n- Budget restrictions' +
          '\n- Targeting settings' +
          '\n- Billing issues'
  });
  
  Logger.log('Alert email sent to ' + YOUR_EMAIL);
}`;

export const LEVEL_10_CODE = `function main() {
  Logger.log("Log into the app & use your new script skills to complete your BudgetMax setup.");
}`;
