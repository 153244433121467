import React from 'react';
import { Alert, AlertDescription } from "@/components/ui/alert";
import type { ProcessedMetrics, CurvePoint, CampaignWithShare, OptimalZone } from '@/types/metrics';

interface LeadGenPanelProps {
  currentMetrics: {
    cost: number;
    conv: number;
    value: number;
    profit: number;
  };
  campaign?: CampaignWithShare;
  curvePoints?: CurvePoint[];
  optimalZone: OptimalZone;
  breakEvenCpa: number;
  selectedCurrency: string;
}

export function LeadGenPanel({
  currentMetrics,
  campaign,
  curvePoints,
  optimalZone,
  breakEvenCpa,
  selectedCurrency
}: LeadGenPanelProps) {
  const formatNumber = (num: number) => Math.round(num).toLocaleString();
  const formatCurrency = (num: number) => `${selectedCurrency}${formatNumber(num)}`;

  // Calculate current CPA
  const cpa = currentMetrics.conv > 0 ? currentMetrics.cost / currentMetrics.conv : 0;

  // Calculate incremental metrics
  const currentIndex = curvePoints?.findIndex(p => p.cost >= currentMetrics.cost) || -1;
  const nextPoint = curvePoints?.[currentIndex + 1];
  const currentPoint = curvePoints?.[currentIndex];

  // Find maximum conversions from curve points
  const maxConv = curvePoints?.reduce((max, point) => Math.max(max, point.conv), 0) || 0;

  // Calculate leads per next $100
  const leadsPerHundred = (() => {
    if (!nextPoint || !currentPoint) return 0;
    const costDiff = 100; // Next $100
    const convDiff = (nextPoint.conv - currentPoint.conv) * (costDiff / (nextPoint.cost - currentPoint.cost));
    return convDiff;
  })();

  // Calculate cost for next conversion
  const nextConversionCost = (() => {
    if (!nextPoint || !currentPoint) return 0;
    const costDiff = nextPoint.cost - currentPoint.cost;
    const convDiff = nextPoint.conv - currentPoint.conv;
    return convDiff > 0 ? costDiff / convDiff : 0;
  })();

  // Calculate break-even leads and surplus
  const breakEvenLeads = currentMetrics.cost / breakEvenCpa;
  const leadsSurplus = currentMetrics.conv - breakEvenLeads;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-6">
        {/* Optimal Range Analysis */}
        <div>
          <h3 className="font-semibold mb-3 text-lg">Optimal Range</h3>
          <table className="w-full">
            <tbody className="divide-y divide-border">
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Range Start</div>
                  <div className="font-medium">{formatCurrency(optimalZone.start)}</div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Range End</div>
                  <div className="font-medium">{formatCurrency(optimalZone.end)}</div>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Maximum Profit</div>
                  <div className="font-medium text-green-500">
                    {formatCurrency(optimalZone.maxProfit)}
                  </div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Maximum Conv</div>
                  <div className="font-medium">
                    {formatNumber(maxConv)}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* CPA Analysis */}
        <div>
          <h3 className="font-semibold mb-3 text-lg">CPA Analysis</h3>
          <table className="w-full">
            <tbody className="divide-y divide-border">
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Current CPA</div>
                  <div className="font-medium">{formatCurrency(cpa)}</div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Break-even CPA</div>
                  <div className="font-medium">{formatCurrency(breakEvenCpa)}</div>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Conv per {formatCurrency(100)}</div>
                  <div className="font-medium">{leadsPerHundred.toFixed(1)}</div>
                </td>
                <td className="py-2">
                  <div className="text-sm text-muted-foreground">Next Conv Cost</div>
                  <div className="font-medium">{formatCurrency(nextConversionCost)}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Current Status */}
        <div>
          <h3 className="font-semibold mb-3 text-lg">Current Status</h3>
          {currentMetrics.cost < optimalZone.start ? (
            <Alert variant="warning">
              <AlertDescription className="text-lg">
                Underspending by {formatCurrency(optimalZone.start - currentMetrics.cost)}.
                Consider increasing budget to reach optimal zone.
              </AlertDescription>
            </Alert>
          ) : currentMetrics.cost > optimalZone.end ? (
            <Alert variant="destructive">
              <AlertDescription className="text-lg">
                Overspending by {formatCurrency(currentMetrics.cost - optimalZone.end)}.
                Consider reducing spend to improve profitability.
              </AlertDescription>
            </Alert>
          ) : (
            <Alert variant="success">
              <AlertDescription className="text-lg">
                Currently within optimal profit zone.
                Monitor performance to maintain position.
              </AlertDescription>
            </Alert>
          )}
        </div>
      </div>

      {/* Current Metrics */}
      <div className="grid grid-cols-5 gap-6">
        <div>
          <div className="text-sm font-medium text-muted-foreground mb-1">Projected Cost</div>
          <div className="text-2xl font-bold">{formatCurrency(currentMetrics.cost)}</div>
        </div>
        <div>
          <div className="text-sm font-medium text-muted-foreground mb-1">Projected Conv</div>
          <div className="text-2xl font-bold">{formatNumber(currentMetrics.conv)}</div>
        </div>
        <div>
          <div className="text-sm font-medium text-muted-foreground mb-1">Projected Profit</div>
          <div className="text-2xl font-bold">{formatCurrency(currentMetrics.profit)}</div>
        </div>
        <div>
          <div className="text-sm font-medium text-muted-foreground mb-1">Break-even Conv</div>
          <div className="text-2xl font-bold">{formatNumber(breakEvenLeads)}</div>
        </div>
        <div>
          <div className="text-sm font-medium text-muted-foreground mb-1">Conv Surplus</div>
          <div className={`text-2xl font-bold ${leadsSurplus >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {formatNumber(leadsSurplus)}
          </div>
        </div>
      </div>
    </div>
  );
}