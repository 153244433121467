// src/pages/setup.tsx
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAccounts } from "@/contexts/account-context"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import { ExternalLink, Copy } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Loader2 } from "lucide-react"
import { getGoogleAdsScript } from "@/lib/google-ads-script-template"
import { cn } from "@/lib/utils"
import { db } from '@/services/db'
import { fetchAllData } from '@/lib/data-utils'
import { useUser } from "@clerk/clerk-react"
import { Account } from "@/types/metrics"
import { storeTempWebAppUrlWithTimestamp } from '@/lib/storage-utils'

const DEBUG = process.env.NODE_ENV === 'development'
const TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1XmQYHWYbQbtn6mnrGYB71dsixbp66Eo3n5o7fka_s10/copy"

export default function Setup() {
  const navigate = useNavigate()
  const { toast } = useToast()
  const { user, isSignedIn } = useUser()
  const [showAuthDialog, setShowAuthDialog] = useState(false)
  const { addAccount, accounts } = useAccounts()

  const [setupState, setSetupState] = useState({
    sheetUrl: '',
    webAppUrl: '',
    currentStep: 1,
    scriptCopied: false
  })

  const [isValidating, setIsValidating] = useState(false)
  const [loadingDialog, setLoadingDialog] = useState<{
    title: string
    description: string
    open: boolean
  } | null>(null)

  // Copy script helper
  const copyScript = async () => {
    if (DEBUG) console.log('Copying script for sheet URL:', setupState.sheetUrl)
    try {
      const script = getGoogleAdsScript(setupState.sheetUrl)
      await navigator.clipboard.writeText(script)
      setSetupState(prev => ({ ...prev, scriptCopied: true }))
      toast({
        title: "Script Copied",
        description: "The script has been copied to your clipboard"
      })
    } catch (err) {
      console.error('Failed to copy script:', err)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to copy script"
      })
    }
  }

  const validateWebAppUrl = (url: string): boolean => {
    try {
      const parsed = new URL(url)
      // Check if it's a Google Apps Script URL
      return parsed.hostname === 'script.google.com' || 
             parsed.hostname.endsWith('.googleusercontent.com')
    } catch {
      return false
    }
  }

  // Handle final setup step
  const handleWebAppUrlUpdate = async (url: string) => {
    if (!url) {
      toast({
        variant: "destructive",
        title: "Invalid URL",
        description: "Please enter a Web App URL"
      })
      return
    }

    if (!validateWebAppUrl(url)) {
      toast({
        variant: "destructive",
        title: "Invalid URL",
        description: "Please enter a valid Google Apps Script Web App URL"
      })
      return
    }

    if (!isSignedIn) {
      setShowAuthDialog(true)
      return
    }

    // Check for duplicate URL
    const existingAccount = accounts.find(a => a.webAppUrl === url)
    if (existingAccount) {
      toast({
        variant: "destructive",
        title: "Account Already Exists",
        description: `This Google Ads account is already connected to "${existingAccount.name}". Please use a different account.`
      })
      return
    }

    setIsValidating(true)
    setLoadingDialog({
      title: 'Setting Up Account',
      description: 'Creating account and loading initial data...',
      open: true
    })

    try {
      // Create account first
      const response = await fetch(`${url}?tab=account`)
      if (!response.ok) {
        throw new Error(`Failed to fetch account info: ${response.statusText}`)
      }

      const accountData = await response.json()
      const accountInfo = Array.isArray(accountData) ? accountData[0] : accountData

      if (!accountInfo?.accountId && !accountInfo?.accountName) {
        throw new Error('Invalid account data received')
      }

      const accountCID = accountInfo.accountId || accountInfo.accountCID
      const newAccount: Account = {
        id: accountCID,
        dexieId: accountCID,
        accountCID,
        name: accountInfo.accountName,
        webAppUrl: url,
        businessMode: 'ecomm',
        currency: '$',
        cogsAmount: 50,
        breakEvenCpa: 25,
        isSampleAccount: false
      }

      const savedAccount = await addAccount(newAccount)

      // Update loading message for data fetch
      setLoadingDialog({
        title: 'Loading Data',
        description: 'Fetching campaign data...',
        open: true
      })

      // Load initial data
      const initialData = await fetchAllData(savedAccount.dexieId, url)
      await db.saveAccountData(savedAccount.dexieId, initialData)

      // Update Clerk metadata if signed in
      if (isSignedIn && user) {
        await user.update({
          unsafeMetadata: {
            lastActiveDexieId: savedAccount.dexieId
          }
        })
      }

      toast({
        title: 'Success',
        description: `${newAccount.name} has been connected successfully`
      })

      navigate('/settings')

    } catch (err) {
      console.error('Setup error:', err)
      toast({
        variant: 'destructive',
        title: 'Setup Failed',
        description: err instanceof Error ? err.message : 'Failed to set up account'
      })
    } finally {
      setIsValidating(false)
      setLoadingDialog(null)
    }
  }

  const handleWebAppUrlSubmit = (url: string) => {
    // Store the URL before redirecting
    storeTempWebAppUrlWithTimestamp(url)

    // If not signed in, redirect to sign up
    if (!isSignedIn) {
      navigate('/sign-up')
    } else {
      // If signed in, go directly to settings
      navigate('/settings')
    }
  }

  const steps = [
    {
      number: 1,
      title: "Create Sheet",
      description: "Copy template",
      instructions: (
        <div className="space-y-4">
          <p>First, create a copy of the template sheet that will store your campaign data</p>
          <p>Set it so 'Anyone with link' can edit it. (This is important for the script to work)</p>
          <p>Then paste the URL of your sheet in the space below & click Continue.</p>
          <Button
            onClick={() => window.open(TEMPLATE_URL, '_blank')}
            className="flex items-center space-x-2"
          >
            <ExternalLink className="h-4 w-4" />
            <span>Create Copy of Template</span>
          </Button>
          <Input
            value={setupState.sheetUrl}
            onChange={(e) => setSetupState(prev => ({
              ...prev,
              sheetUrl: e.target.value
            }))}
            placeholder="Paste your sheet URL here"
          />
          <Button
            onClick={() => setSetupState(prev => ({ ...prev, currentStep: 2 }))}
            disabled={!setupState.sheetUrl.includes('sheet')}
          >
            Continue
          </Button>
        </div>
      )
    },
    {
      number: 2,
      title: "Install Script",
      description: "Set up Google Ads script",
      instructions: (
        <div className="space-y-4">
          <p>The script has been created with your sheet url, all you have to do is copy & paste.</p>
          <p>(If you need help DM Mike or for a 14min script course, click 'Scary!' in the nav bar)</p>
          <p>Paste this script in your Google Ads account, Authorise it & run it once to verify it works.</p>
          <p>You'll see the sheet fill with data. Then schedule it to run hourly, and click Continue.</p>
          <Button
            onClick={copyScript}
            variant={setupState.scriptCopied ? "ghost" : "default"}
            className="flex items-center space-x-2"
          >
            <Copy className="h-4 w-4" />
            <span>{setupState.scriptCopied ? 'Copied!' : 'Copy Script'}</span>
          </Button>
          <Button
            onClick={() => setSetupState(prev => ({ ...prev, currentStep: 3 }))}
            disabled={!setupState.scriptCopied}
          >
            Continue
          </Button>
        </div>
      )
    },
    {
      number: 3,
      title: "Connect Account",
      description: "Link your Google Ads account",
      instructions: (
        <div className="space-y-4">
          <p>Enter the Web App URL from your deployed sheet</p>
          <div className="space-y-2 text-sm text-muted-foreground">
            <p>To get your Web App URL:</p>
            <ol className="list-decimal list-inside space-y-1 ml-2">
              <li>Open your Google Ads sheet</li>
              <li>Click 'Extensions' then 'Apps Script'</li>
              <li>Click "Deploy" in the top right</li>
              <li>Select "New deployment"</li>
              <li>Choose "New deployment" again</li>
              <li>Set "Execute as" to your account</li>
              <li>Under "Who has access" select "Anyone"</li>
              <li>Click "Deploy"</li>
              <li>Copy the Web App URL provided, not the ID. The URL is the one that starts script.google.com/...</li>
              <li>(Note: If you close the window by mistake, click Deploy, then Manage Deployments)</li>
            </ol>
          </div>
          <Input
            value={setupState.webAppUrl}
            onChange={(e) => setSetupState(prev => ({
              ...prev,
              webAppUrl: e.target.value
            }))}
            placeholder="Paste your Web App URL here"
          />
          {!isSignedIn && (
            <p className="text-sm text-muted-foreground">
              You'll need to sign in or create an account to connect your Google Ads account.
            </p>
          )}
          <Button
            onClick={() => {
              if (!setupState.webAppUrl) {
                toast({
                  variant: "destructive",
                  title: "Missing URL",
                  description: "Please enter a Web App URL"
                })
                return
              }
              handleWebAppUrlSubmit(setupState.webAppUrl)
            }}
            disabled={!setupState.webAppUrl || !setupState.webAppUrl.includes('macros')}
          >
            Complete Setup
          </Button>
        </div>
      )
    }
  ]

  return (
    <div className="container mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Setup Wizard</CardTitle>
          <CardDescription>Let's get your campaign data connected</CardDescription>
        </CardHeader>
        <CardContent className="space-y-8">
          {steps.map((step) => (
            <div
              key={step.number}
              className={`space-y-4 ${step.number !== setupState.currentStep ? 'opacity-50' : ''}`}
            >
              <div className="flex items-start gap-4">
                <div className={cn(
                  "flex h-8 w-8 shrink-0 items-center justify-center rounded-full border text-center",
                  step.number === setupState.currentStep && "bg-primary text-primary-foreground border-primary",
                  step.number !== setupState.currentStep && "border-muted-foreground"
                )}>
                  {step.number}
                </div>
                <div>
                  <h3 className="font-semibold">{step.title}</h3>
                  <p className="text-sm text-muted-foreground">{step.description}</p>
                </div>
              </div>

              {step.number === setupState.currentStep && (
                <div className="ml-12">
                  {step.instructions}
                </div>
              )}
            </div>
          ))}
        </CardContent>
      </Card>

      {/* Auth Dialog */}
      <Dialog open={showAuthDialog} onOpenChange={setShowAuthDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sign In Required</DialogTitle>
            <p className="text-sm text-muted-foreground pt-2">
              To connect your Google Ads account, you'll need to be signed in.
              Choose an option below to continue:
            </p>
          </DialogHeader>
          <div className="flex flex-col sm:flex-row gap-2 justify-end">
            <Button
              variant="outline"
              onClick={() => {
                navigate('/sign-up', {
                  state: { from: location.pathname }
                })
              }}
            >
              Create Account
            </Button>
            <Button
              onClick={() => {
                navigate('/sign-in', {
                  state: { from: location.pathname }
                })
              }}
            >
              Sign In
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Loading Dialog */}
      {loadingDialog && (
        <Dialog open={loadingDialog.open}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{loadingDialog.title}</DialogTitle>
            </DialogHeader>
            <div className="py-6">
              <div className="flex items-center space-x-4">
                <Loader2 className="h-6 w-6 animate-spin" />
                <p>{loadingDialog.description}</p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
} 