import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";

export default function HelpPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Help & Support</h1>

      {/* Introduction Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="text-lg">
          Welcome to the Budget Maximiser! This application helps you optimize your Google Ads 
          <br />
          campaigns by providing insights and recommendations based on your performance data.
        </p>
      </section>

      {/* Getting Started Guide */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
        <ol className="list-decimal list-inside text-lg">
          <li>Create an account or sign in to access the full features of the app.</li>
          <li>Configure your Google Ads integration in the <Link to="/settings" className="text-primary">Settings</Link> page.</li>
          <li>Start analyzing your campaign data on the <Link to="/analysis" className="text-primary">Analysis</Link> page.</li>
        </ol>
        <Button variant="link" className="mt-4" onClick={() => window.open('https://example.com/tutorial', '_blank')}>
          Watch the Setup Tutorial (coming soon)
        </Button>
      </section>

      {/* Detailed Page Guides */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Page Guides</h2>

        {/* Analysis Page Guide */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Analysis Page</h3>
          <p className="text-lg">
            The Analysis page provides detailed insights into your campaign performance. Here, you can:
          </p>
          <ul className="list-disc list-inside text-lg">
            <li>View key metrics like impressions, clicks, and conversions.</li>
            <li>Use graphs to visualize performance trends over time.</li>
            <li>Compare current performance with previous periods.</li>
            <li>Receive AI-driven optimization suggestions.</li>
          </ul>
        </div>

        {/* Curve Page Guide */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Curve Page</h3>
          <p className="text-lg">
            The Curve page helps you visualize and optimize your profit curves. Features include:
          </p>
          <ul className="list-disc list-inside text-lg">
            <li>Graphical representation of profit against various metrics.</li>
            <li>Break-even analysis to identify revenue and cost balance points.</li>
            <li>Scenario testing to simulate different outcomes.</li>
          </ul>
        </div>
      </section>

      {/* Common Use Cases */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Common Use Cases</h2>
        <p className="text-lg">
          Here are some common tasks you can perform with the app:
        </p>
        <ul className="list-disc list-inside text-lg">
          <li>Optimize a campaign by adjusting budgets and bids based on analysis insights.</li>
          <li>Analyze performance trends to make data-driven decisions.</li>
          <li>Use the curve page to find the optimal profit point for your campaigns.</li>
        </ul>
      </section>

      {/* Troubleshooting */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Troubleshooting</h2>
        <p className="text-lg">
          If you encounter any issues, visit our <a href="https://mikerhodes.circle.so/c/help/" target="_blank" rel="noopener noreferrer" className="text-primary">Troubleshooting</a> page for assistance.
        </p>
      </section>

      {/* Interactive Learning */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Interactive Learning</h2>
        <p className="text-lg">
          Take our interactive quiz to test your knowledge and get personalized tips.
        </p>
        <Button variant="default" className="mt-4 text-xl font-bold py-3 px-6">
          Start The Scary Script Challenge
        </Button>
        <p className="text-lg">
          This is a 14 minute script challenge to boost your confidence with automation.
          <br />
          Once you've completed it, you'll be able to confidently automate your Google Ads campaigns.
          <br />
          And scripts won't be scary anymore.
        </p>
      </section>
    </div>
  );
}