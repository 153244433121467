// src/contexts/campaign-data.tsx
import { createContext, useContext, useMemo, useState, useCallback, useEffect } from 'react'
import { useUser } from '@clerk/clerk-react'
import { useLocation } from 'react-router-dom'
import { Campaign, StorageData } from '@/types/metrics'
import { useAccounts } from './account-context'
import { db } from '@/services/db'
import { DEBUG, SAMPLE_ACCOUNT_ID, SHEET_TABS } from '@/lib/constants'
import { fetchAllData } from '@/lib/data-utils'

// Pages that require data
const DATA_REQUIRED_PATHS = ['/analysis', '/trends', '/curve', '/hourly']

interface CampaignDataContextType {
  data: StorageData | null
  accountData: Record<string, StorageData>
  activeDexieId: string | null
  isLoading: boolean
  loadingStatus: 'idle' | 'initial' | 'refresh' | 'switch'
  error?: string
  lastUpdated: Date | null
  refreshData: (dexieId: string, webAppUrl?: string) => Promise<void>
  useSampleData: boolean
  initialCogsAmount: number
  initialBreakEvenCpa: number
  updateInitialCogsAmount: (value: number) => Promise<void>
  updateInitialBreakEvenCpa: (value: number) => Promise<void>
  switchToSampleData: (value: boolean) => Promise<void>
  currentGameLevel: number
  updateCurrentGameLevel: (level: number) => Promise<void>
  thirtyDayData?: Campaign[]
  isInitialized: boolean
}

const CampaignDataContext = createContext<CampaignDataContextType | null>(null)

export function CampaignDataProvider({ children }: { children: React.ReactNode }) {
  const { isSignedIn, user } = useUser()
  const { activeDexieId, activeAccount } = useAccounts()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState<'idle' | 'initial' | 'refresh' | 'switch'>('idle')
  const [error, setError] = useState<string>()
  const [useSampleData, setUseSampleData] = useState(!isSignedIn)
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
  const [data, setData] = useState<StorageData | null>(null)
  const [accountData, setAccountData] = useState<Record<string, StorageData>>({})
  const [hasSampleDataLoaded, setHasSampleDataLoaded] = useState(false)
  const [currentGameLevel, setCurrentGameLevel] = useState(0)
  const [isInitialized, setIsInitialized] = useState(false)

  // Initialize database and load initial data
  useEffect(() => {
    let mounted = true
    
    const initializeData = async () => {
      if (DEBUG) console.log('CampaignData: Starting initialization')
      try {
        // Wait for database to be ready
        await db.ready()
        
        // If not signed in, ensure sample data is available
        if (!isSignedIn && mounted) {
          if (DEBUG) console.log('CampaignData: Loading sample data')
          await db.ensureSampleAccount()
          const sampleData = await db.getAccountData(SAMPLE_ACCOUNT_ID)
          if (sampleData && mounted) {
            setData(sampleData)
            setAccountData(prev => ({ ...prev, [SAMPLE_ACCOUNT_ID]: sampleData }))
            setLastUpdated(new Date(sampleData.timestamp))
            setHasSampleDataLoaded(true)
          }
        }
        
        if (mounted) {
          if (DEBUG) console.log('CampaignData: Initialization complete')
          setIsInitialized(true)
        }
      } catch (err) {
        console.error('Failed to initialize campaign data:', err)
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to initialize data')
          setIsInitialized(true) // Still mark as initialized so app can show error state
        }
      }
    }

    initializeData()
    return () => { mounted = false }
  }, [isSignedIn])

  // Load initial game level from Clerk metadata
  useEffect(() => {
    if (isSignedIn && user) {
      const savedLevel = user.unsafeMetadata.currentGameLevel
      if (typeof savedLevel === 'number') {
        setCurrentGameLevel(savedLevel)
      }
    } else {
      setCurrentGameLevel(0)
    }
  }, [isSignedIn, user])

  // Update game level
  const updateCurrentGameLevel = useCallback(async (level: number) => {
    try {
      setCurrentGameLevel(level)
      
      // Save to Clerk metadata if signed in
      if (isSignedIn && user) {
        await user.update({
          unsafeMetadata: {
            ...user.unsafeMetadata,
            currentGameLevel: level
          }
        })
      }
    } catch (err) {
      console.error('Failed to update game level:', err)
      throw err
    }
  }, [isSignedIn, user])

  // Load data from Dexie when needed
  const refreshData = useCallback(async (dexieId: string, webAppUrl?: string) => {
    setIsLoading(true)
    setLoadingStatus('refresh')
    setError(undefined) // Clear any previous errors
    
    try {
      let newData: StorageData | null = null

      // If webAppUrl provided, fetch fresh data
      if (webAppUrl) {
        console.log('CampaignData: Fetching fresh data from:', webAppUrl)
        newData = await fetchAllData(dexieId, webAppUrl)
        // Save to Dexie
        await db.saveAccountData(dexieId, newData)
      } else {
        // Try to load from Dexie
        const storedData = await db.getAccountData(dexieId)
        if (storedData) {
          console.log('CampaignData: Using stored data for:', dexieId)
          newData = storedData
        } else if (activeAccount?.webAppUrl) {
          // If no stored data but we have URL, fetch fresh
          console.log('CampaignData: No stored data, fetching fresh from:', activeAccount.webAppUrl)
          newData = await fetchAllData(dexieId, activeAccount.webAppUrl)
          await db.saveAccountData(dexieId, newData)
        }
      }

      if (newData) {
        console.log('CampaignData: Setting new data:', {
          dexieId,
          timestamp: newData.timestamp,
          hasAccount: !!newData[SHEET_TABS.ACCOUNT]?.length,
          hasDaily: !!newData[SHEET_TABS.DAILY]?.length,
          hasThirtyDays: !!newData[SHEET_TABS.THIRTY_DAYS]?.length
        })
        setData(newData)
        setAccountData(prev => ({ ...prev, [dexieId]: newData }))
        setLastUpdated(new Date(newData.timestamp))
      }
    } catch (err) {
      console.error('CampaignData: Error refreshing data:', err)
      setError(err instanceof Error ? err.message : 'Unknown error')
      throw err // Re-throw to allow handling in UI
    } finally {
      setIsLoading(false)
      setLoadingStatus('idle')
    }
  }, [activeAccount])

  // Auto-load sample data after initial load
  useEffect(() => {
    if (!isSignedIn && !hasSampleDataLoaded) {
      const loadSampleData = async () => {
        try {
          console.log('CampaignData: Auto-loading sample data...')
          // Check if sample data exists in Dexie
          const storedData = await db.getAccountData(SAMPLE_ACCOUNT_ID)
          if (!storedData) {
            // If no stored data, fetch and store it
            await refreshData(SAMPLE_ACCOUNT_ID)
          } else {
            // Use stored sample data
            console.log('CampaignData: Using stored sample data')
            setData(storedData)
            setAccountData(prev => ({ ...prev, [SAMPLE_ACCOUNT_ID]: storedData }))
            setLastUpdated(new Date(storedData.timestamp))
          }
          setHasSampleDataLoaded(true)
        } catch (err) {
          console.error('CampaignData: Failed to auto-load sample data:', err)
        }
      }

      // Wait a bit after initial load to avoid blocking rendering
      const timer = setTimeout(loadSampleData, 2000)
      return () => clearTimeout(timer)
    }
  }, [isSignedIn, hasSampleDataLoaded, refreshData])

  // Load sample data if user visits a data page without data
  useEffect(() => {
    if (!isSignedIn && DATA_REQUIRED_PATHS.includes(location.pathname) && !data && !isLoading) {
      const loadSampleData = async () => {
        try {
          console.log('Loading sample data for data page...')
          await refreshData(SAMPLE_ACCOUNT_ID)
          setHasSampleDataLoaded(true)
        } catch (err) {
          console.error('Failed to load sample data for data page:', err)
        }
      }
      loadSampleData()
    }
  }, [isSignedIn, location.pathname, data, isLoading, refreshData])

  // Switch to/from sample data
  const switchToSampleData = useCallback(async (value: boolean) => {
    setUseSampleData(value)
    if (value) {
      // When switching to sample data, load it from Dexie
      await refreshData(SAMPLE_ACCOUNT_ID)
    } else {
      // When switching away, clear sample data
      setData(null)
      setAccountData({})
    }
  }, [refreshData])

  // Load initial data for active account
  useEffect(() => {
    if (!activeDexieId || !activeAccount?.webAppUrl) return
    
    const loadInitialData = async () => {
      const storedData = await db.getAccountData(activeDexieId)
      if (!storedData) {
        // No stored data, fetch fresh
        await refreshData(activeDexieId, activeAccount.webAppUrl)
      } else {
        // Use stored data
        setData(storedData)
        setAccountData(prev => ({ ...prev, [activeDexieId]: storedData }))
        setLastUpdated(new Date(storedData.timestamp))
      }
    }

    loadInitialData()
  }, [activeDexieId, activeAccount, refreshData])

  const contextValue = useMemo<CampaignDataContextType>(() => ({
    data,
    accountData,
    activeDexieId,
    isLoading,
    loadingStatus,
    error,
    lastUpdated,
    refreshData,
    useSampleData,
    initialCogsAmount: activeAccount?.cogsAmount || 0,
    initialBreakEvenCpa: activeAccount?.breakEvenCpa || 0,
    updateInitialCogsAmount: async () => {},
    updateInitialBreakEvenCpa: async () => {},
    switchToSampleData,
    currentGameLevel,
    updateCurrentGameLevel,
    thirtyDayData: data?.thirty_days,
    isInitialized
  }), [
    data, accountData, activeDexieId, isLoading, loadingStatus, error,
    lastUpdated, refreshData, useSampleData, activeAccount,
    currentGameLevel, updateCurrentGameLevel, isInitialized
  ])

  // Show loading state while initializing
  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    )
  }

  return (
    <CampaignDataContext.Provider value={contextValue}>
      {children}
    </CampaignDataContext.Provider>
  )
}

export function useCampaignData() {
  const context = useContext(CampaignDataContext)
  if (!context) {
    throw new Error('useCampaignData must be used within CampaignDataProvider')
  }
  return context
}