// src/lib/metrics.ts
import { BaseMetrics } from '@/types/metrics';
import { useCurrency } from '@/hooks/use-currency';

// Define chart colors
export const CHART_COLORS = {
  primary: '#d946ef', // Former CPA color
  secondary: '#7c3aed' // Former CPC color
} as const;

// Extend the base metrics with calculated metrics
export type MetricKey = 
  | 'Impressions' 
  | 'Clicks' 
  | 'Cost' 
  | 'Conversions' 
  | 'ConvValue'
  | 'CTR'
  | 'CPC'
  | 'CPA'
  | 'ROAS'
  | 'AOV'
  | 'ImprShare'
  | 'LostToBudget'
  | 'LostToRank';

export interface MetricConfig {
  label: string;
  format: (value: number, currency?: string) => string;
  color?: string; // Make color optional since it will be assigned dynamically
  calculate?: (data: BaseMetrics[]) => number;
  calculateFromBase?: (metrics: BaseMetrics, options?: { prevMetrics?: BaseMetrics }) => number;
  inverse?: boolean;
}

export const METRIC_FORMATTERS = {
  currency: (value: number, currency = '$') => 
    `${currency}${Math.round(value).toLocaleString()}`,
  currencyPrecise: (value: number, currency = '$') => 
    `${currency}${value.toFixed(2)}`,
  number: (value: number) => 
    Math.round(value).toLocaleString(),
  percentage: (value: number) => 
    `${value.toFixed(1)}%`,
  roas: (value: number) => 
    `${value.toFixed(1)}x`,
} as const;

export const METRIC_CONFIGS: Record<MetricKey, MetricConfig> = {
  // Base metrics
  Impressions: {
    label: 'Impressions',
    format: METRIC_FORMATTERS.number,
  },
  Clicks: {
    label: 'Clicks',
    format: METRIC_FORMATTERS.number,
  },
  Cost: {
    label: 'Cost',
    format: METRIC_FORMATTERS.currency,
  },
  Conversions: {
    label: 'Conv.',
    format: METRIC_FORMATTERS.number,
  },
  ConvValue: {
    label: 'Value',
    format: METRIC_FORMATTERS.currency,
  },
  ImprShare: {
    label: 'Impr. Share',
    format: METRIC_FORMATTERS.percentage,
  },
  LostToBudget: {
    label: 'Lost (Budget)',
    format: METRIC_FORMATTERS.percentage,
  },
  LostToRank: {
    label: 'Lost (Rank)',
    format: METRIC_FORMATTERS.percentage,
  },
  // Calculated metrics
  CTR: {
    label: 'CTR',
    format: METRIC_FORMATTERS.percentage,
    calculate: (data) => {
      const totalImpressions = data.reduce((sum, row) => sum + (row.impressions || 0), 0);
      const totalClicks = data.reduce((sum, row) => sum + (row.clicks || 0), 0);
      return totalImpressions ? (totalClicks / totalImpressions) * 100 : 0;
    }
  },
  CPC: {
    label: 'CPC',
    format: METRIC_FORMATTERS.currencyPrecise,
    calculate: (data) => {
      const totalCost = data.reduce((sum, row) => sum + (row.cost || 0), 0);
      const totalClicks = data.reduce((sum, row) => sum + (row.clicks || 0), 0);
      return totalClicks ? totalCost / totalClicks : 0;
    }
  },
  CPA: {
    label: 'CPA',
    format: METRIC_FORMATTERS.currency,
    inverse: true,
    calculate: (data) => {
      const totalCost = data.reduce((sum, row) => sum + (row.cost || 0), 0);
      const totalConversions = data.reduce((sum, row) => sum + (row.conversions || 0), 0);
      return totalConversions ? totalCost / totalConversions : 0;
    }
  },
  ROAS: {
    label: 'ROAS',
    format: METRIC_FORMATTERS.roas,
    calculate: (data) => {
      const totalCost = data.reduce((sum, row) => sum + (row.cost || 0), 0);
      const totalValue = data.reduce((sum, row) => sum + (row.convValue || 0), 0);
      return totalCost ? totalValue / totalCost : 0;
    }
  },
  AOV: {
    label: 'AOV',
    format: METRIC_FORMATTERS.currency,
    calculate: (data) => {
      const totalValue = data.reduce((sum, row) => sum + (row.convValue || 0), 0);
      const totalConversions = data.reduce((sum, row) => sum + (row.conversions || 0), 0);
      return totalConversions ? totalValue / totalConversions : 0;
    }
  }
};

// Helper to get metric color based on index
export function getMetricColor(index: number): string {
  return index === 0 ? CHART_COLORS.primary : CHART_COLORS.secondary;
}

// Helper to calculate derived metrics for a single row
export function calculateDerivedMetrics<T extends BaseMetrics>(row: T): T & Record<MetricKey, number> {
  return {
    ...row,
    Impressions: row.impressions,
    Clicks: row.clicks,
    Cost: row.cost,
    Conversions: row.conversions,
    ConvValue: row.convValue,
    CTR: row.impressions ? (row.clicks / row.impressions) * 100 : 0,
    CPC: row.clicks ? row.cost / row.clicks : 0,
    CPA: row.conversions ? row.cost / row.conversions : 0,
    ROAS: row.cost ? row.convValue / row.cost : 0,
    AOV: row.conversions ? row.convValue / row.conversions : 0,
    ImprShare: row.imprShare || 0,
    LostToBudget: row.lostToBudget || 0,
    LostToRank: row.lostRank || 0
  };
}

// Helper to calculate totals for any data type extending BaseMetrics
export function calculateTotals<T extends BaseMetrics>(data: T[]): BaseMetrics {
  return data.reduce((acc, row) => ({
    impressions: acc.impressions + (row.impressions || 0),
    clicks: acc.clicks + (row.clicks || 0),
    cost: acc.cost + (row.cost || 0),
    conversions: acc.conversions + (row.conversions || 0),
    convValue: acc.convValue + (row.convValue || 0),
    imprShare: acc.imprShare + (row.imprShare || 0),
    lostToBudget: acc.lostToBudget + (row.lostToBudget || 0),
    lostRank: acc.lostRank + (row.lostRank || 0)
  }), {
    impressions: 0,
    clicks: 0,
    cost: 0,
    conversions: 0,
    convValue: 0,
    imprShare: 0,
    lostToBudget: 0,
    lostRank: 0
  });
}

// Hook for formatting metrics with current currency
export function useMetricFormatter(currency?: string) {
  return (metric: MetricKey, value: number) => {
    const config = METRIC_CONFIGS[metric];
    if (!config) return value.toString();
    
    const needsCurrency = metric === 'Cost' || metric === 'ConvValue' || 
                         metric === 'CPC' || metric === 'CPA' || metric === 'AOV';
                         
    return config.format(value, needsCurrency ? currency : undefined);
  };
}