// src/components/sample-data-banner.tsx
import { useCampaignData } from "@/contexts/campaign-data"
import { AlertCircle } from "lucide-react"

export const SampleDataBanner = () => {
  const { useSampleData } = useCampaignData()

  return (
    <div className="bg-orange-400/95 dark:bg-orange-600/95 border-b-2 border-orange-500 dark:border-orange-500">
      <div className="container flex items-center gap-4 px-6 py-6 text-lg font-medium text-orange-950 dark:text-white">
        <AlertCircle className="h-6 w-6" />
        <p>Currently viewing sample data - Sign Up to see your own data (or Sign In if you already have an account)</p>
      </div>
    </div>
  )
}