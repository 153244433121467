// src/pages/settings.tsx
'use client'

import { useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo, useCallback, memo } from "react"
import { Plus, Trash2, RefreshCw, Loader2, Pencil } from "lucide-react"
import { useUser } from '@clerk/clerk-react'
import { getTempWebAppUrlWithTimestamp, clearTempWebAppUrl } from '@/lib/storage-utils'
import { cn } from "@/lib/utils"

import { SHEET_TABS, CURRENCIES } from '@/lib/constants'
import { useAccounts } from "@/contexts/account-context"
import { useCampaignData } from "@/contexts/campaign-data"
import { Account, StorageData } from "@/types/metrics"
import { db } from '@/services/db'
import { fetchAllData } from '@/lib/data-utils'

import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { DataSourceStatus } from '@/components/data-source-status'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { debugDataLoading } from '@/lib/debug-data-loading'

const DEBUG = process.env.NODE_ENV === 'development'

interface AccountCardProps {
  account: Account
  localInputs: Record<string, { cogsAmount?: number, breakEvenCpa?: number }>
  localBusinessMode: Record<string, 'ecomm' | 'leadgen'>
  localCurrency: Record<string, string>
  onInputChange: (dexieId: string, field: 'cogsAmount' | 'breakEvenCpa', value: string) => void
  onInputBlur: (account: Account, field: 'cogsAmount' | 'breakEvenCpa') => void
  onBusinessModeChange: (account: Account, checked: boolean) => void
  onCurrencyChange: (account: Account, value: string) => void
}

interface AccountsListProps {
  accounts: Account[]
  editingAccount: string | null
  newName: string
  accountData: Record<string, StorageData>
  refreshingAccounts: Record<string, boolean>
  localInputs: Record<string, { cogsAmount?: number, breakEvenCpa?: number }>
  localBusinessMode: Record<string, 'ecomm' | 'leadgen'>
  localCurrency: Record<string, string>
  setNewName: (name: string) => void
  setEditingAccount: (id: string | null) => void
  handleEditName: (dexieId: string, name: string) => void
  handleRefresh: (dexieId: string) => void
  getAccountCID: (dexieId: string) => string
  onInputChange: AccountCardProps['onInputChange']
  onInputBlur: AccountCardProps['onInputBlur']
  onBusinessModeChange: AccountCardProps['onBusinessModeChange']
  onCurrencyChange: AccountCardProps['onCurrencyChange']
  deleteAccount: (dexieId: string) => Promise<void>
}

const AccountCard = memo((props: AccountCardProps) => {
  const {
    account,
    localInputs,
    localBusinessMode,
    localCurrency,
    onInputChange,
    onInputBlur,
    onBusinessModeChange,
    onCurrencyChange
  } = props

  return (
    <div className="grid grid-cols-3 items-center mb-2">
      <div className="flex items-center gap-2">
        <Label className="whitespace-nowrap">Business Mode:</Label>
        <div className="flex items-center gap-2">
          <span className="text-sm">Lead Gen</span>
          <Switch
            checked={localBusinessMode[account.dexieId] === 'ecomm'}
            onCheckedChange={(checked) => onBusinessModeChange(account, checked)}
          />
          <span className="text-sm">E-commerce</span>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <Label>Currency:</Label>
        <Select
          value={localCurrency[account.dexieId]}
          onValueChange={(value) => onCurrencyChange(account, value)}
        >
          <SelectTrigger className="w-[120px] ml-2">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {CURRENCIES.map((c) => (
              <SelectItem key={c.symbol} value={c.symbol}>
                {c.label} ({c.symbol})
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex items-center justify-end gap-2">
        <Label className="whitespace-nowrap">
          {localBusinessMode[account.dexieId] === 'ecomm' ? 'COGS %:' : 'Break-even CPA:'}
        </Label>
        <Input
          type="number"
          className="w-[120px] text-center"
          value={localInputs[account.dexieId]?.[localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'] ?? ''}
          onChange={(e) => {
            const field = localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'
            onInputChange(account.dexieId, field, e.target.value)
          }}
          onBlur={() => {
            const field = localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'
            onInputBlur(account, field)
          }}
          onEnterPress={() => {
            const field = localBusinessMode[account.dexieId] === 'ecomm' ? 'cogsAmount' : 'breakEvenCpa'
            onInputBlur(account, field)
          }}
        />
      </div>
    </div>
  )
}, (prevProps, nextProps) => {
  const prevInput = prevProps.localInputs[prevProps.account.dexieId] || {}
  const nextInput = nextProps.localInputs[nextProps.account.dexieId] || {}
  const prevMode = prevProps.localBusinessMode[prevProps.account.dexieId]
  const nextMode = nextProps.localBusinessMode[prevProps.account.dexieId]
  const prevCurrency = prevProps.localCurrency[prevProps.account.dexieId]
  const nextCurrency = nextProps.localCurrency[prevProps.account.dexieId]

  return (
    prevProps.account.dexieId === nextProps.account.dexieId &&
    prevInput.cogsAmount === nextInput.cogsAmount &&
    prevInput.breakEvenCpa === nextInput.breakEvenCpa &&
    prevMode === nextMode &&
    prevCurrency === nextCurrency
  )
})

const AccountsList = memo((props: AccountsListProps) => {
  const {
    accounts,
    editingAccount,
    newName,
    accountData,
    refreshingAccounts,
    localInputs,
    localBusinessMode,
    localCurrency,
    setNewName,
    setEditingAccount,
    handleEditName,
    handleRefresh,
    getAccountCID,
    onInputChange,
    onInputBlur,
    onBusinessModeChange,
    onCurrencyChange,
    deleteAccount
  } = props

  return accounts.map(account => (
    <Card key={account.dexieId}>
      <CardHeader className="flex flex-row items-center justify-between">
        <div className="flex items-center gap-4">
          <div>
            <div className="flex items-center gap-2">
              <CardTitle>{account.name}</CardTitle>
              <Dialog
                open={editingAccount === account.dexieId}
                onOpenChange={(open) => {
                  if (open) {
                    setNewName(account.name)
                    setEditingAccount(account.dexieId)
                  } else {
                    setEditingAccount(null)
                  }
                }}
              >
                <DialogTrigger asChild>
                  <Button variant="ghost" size="icon" className="h-8 w-8">
                    <Pencil className="h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Edit Account Name</DialogTitle>
                  </DialogHeader>
                  <div className="space-y-4 py-4">
                    <div className="space-y-2">
                      <Label htmlFor="name">Account Name</Label>
                      <Input
                        id="name"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        onEnterPress={() => {
                          if (newName.trim() && newName.trim() !== account.name) {
                            handleEditName(account.dexieId, newName)
                          }
                        }}
                      />
                    </div>
                    <Button
                      onClick={() => handleEditName(account.dexieId, newName)}
                      disabled={!newName.trim() || newName.trim() === account.name}
                    >
                      Save Changes
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              {!account.isSampleAccount && (
                <span className="text-sm text-muted-foreground ml-4">
                  CID: {getAccountCID(account.dexieId)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleRefresh(account.dexieId)}
            className="gap-2"
            disabled={refreshingAccounts[account.dexieId] || account.isSampleAccount}
          >
            {refreshingAccounts[account.dexieId] ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <RefreshCw className="h-4 w-4" />
            )}
            Refresh
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="destructive"
                size="sm"
                className={cn(
                  account.isSampleAccount && "opacity-50 cursor-not-allowed"
                )}
                disabled={account.isSampleAccount || refreshingAccounts[account.dexieId]}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete Account</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete {account.name}? This cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-destructive text-destructive-foreground"
                  onClick={async () => {
                    try {
                      await deleteAccount(account.dexieId)
                    } catch (err) {
                      console.error('Failed to delete account:', err)
                    }
                  }}
                >
                  Delete Account
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </CardHeader>
      <CardContent>
        <AccountCard
          account={account}
          localInputs={localInputs}
          localBusinessMode={localBusinessMode}
          localCurrency={localCurrency}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
          onBusinessModeChange={onBusinessModeChange}
          onCurrencyChange={onCurrencyChange}
        />
        <Accordion type="single" collapsible className="mt-1">
          <AccordionItem value="data-sources">
            <AccordionTrigger>
              <div className="flex items-center gap-2">
                <span>Data Sources</span>
                <span className="text-xs text-muted-foreground">
                  Last updated: {accountData[account.dexieId]?.timestamp
                    ? new Date(accountData[account.dexieId].timestamp).toLocaleString()
                    : 'Never'}
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <DataSourceStatus
                dexieId={account.dexieId}
                isRefreshing={refreshingAccounts[account.dexieId]}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
    </Card>
  ))
})

export default function Settings() {
  const navigate = useNavigate()
  const { accounts, deleteAccount, updateAccount, addAccount } = useAccounts()
  const { refreshData, data, accountData, loadingStatus } = useCampaignData()
  const { toast } = useToast()
  const { isSignedIn, user } = useUser()

  const [editingAccount, setEditingAccount] = useState<string | null>(null)
  const [newName, setNewName] = useState("")
  const [localInputs, setLocalInputs] = useState<Record<string, { cogsAmount?: number, breakEvenCpa?: number }>>({})
  const [localBusinessMode, setLocalBusinessMode] = useState<Record<string, 'ecomm' | 'leadgen'>>({})
  const [localCurrency, setLocalCurrency] = useState<Record<string, string>>({})
  const [refreshingAccounts, setRefreshingAccounts] = useState<Record<string, boolean>>({})
  const [loadingDialog, setLoadingDialog] = useState<{
    title: string
    description: string
    open: boolean
  } | null>(null)

  const initialState = useMemo(() => ({
    inputs: accounts.reduce((acc, account) => ({
      ...acc,
      [account.dexieId]: {
        cogsAmount: account.cogsAmount,
        breakEvenCpa: account.breakEvenCpa
      }
    }), {}),
    businessModes: accounts.reduce((acc, account) => ({
      ...acc,
      [account.dexieId]: account.businessMode
    }), {}),
    currencies: accounts.reduce((acc, account) => ({
      ...acc,
      [account.dexieId]: account.currency
    }), {})
  }), [accounts])

  useEffect(() => {
    setLocalInputs(initialState.inputs)
    setLocalBusinessMode(initialState.businessModes)
    setLocalCurrency(initialState.currencies)
  }, [initialState])

  const getAccountCID = useCallback((dexieId: string): string => {
    const account = accounts.find(a => a.dexieId === dexieId)
    return account?.accountCID || 'Not available'
  }, [accounts])

  const handleRefresh = useCallback(async (dexieId: string) => {
    try {
      const account = accounts.find(a => a.dexieId === dexieId)
      if (!account) return

      if (account.isSampleAccount) {
        toast({ title: "Sample Account", description: "Sample data doesn't need to be refreshed" })
        return
      }

      if (!account.webAppUrl) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "No Web App URL found for this account"
        })
        return
      }

      setRefreshingAccounts(prev => ({ ...prev, [dexieId]: true }))
      await refreshData(dexieId, account.webAppUrl)
      toast({ title: "Data Refreshed", description: "Account data has been updated" })
    } catch (err) {
      console.error('Refresh error:', err)
      toast({ variant: "destructive", title: "Error", description: "Failed to refresh data" })
    } finally {
      setRefreshingAccounts(prev => ({ ...prev, [dexieId]: false }))
    }
  }, [accounts, refreshData, toast])

  const handleEditName = useCallback(async (dexieId: string, name: string) => {
    const account = accounts.find(a => a.dexieId === dexieId)
    if (!account) return

    const previousName = account.name
    const trimmedName = name.trim()

    // Optimistically update UI by updating accounts context
    try {
      await updateAccount({ ...account, name: trimmedName })
      toast({ title: "Account Updated", description: "Account name has been updated" })
      setEditingAccount(null)
    } catch (err) {
      // On error, the accounts context will automatically revert
      toast({ variant: "destructive", title: "Error", description: "Failed to update account name" })
    }
  }, [accounts, updateAccount, toast])

  const handleInputChange = useCallback((dexieId: string, field: 'cogsAmount' | 'breakEvenCpa', value: string) => {
    const numValue = Number(value)
    if (isNaN(numValue)) return

    // Optimistically update local state
    setLocalInputs(prev => ({
      ...prev,
      [dexieId]: {
        ...prev[dexieId],
        [field]: numValue
      }
    }))
  }, [])

  const handleInputBlur = useCallback(async (account: Account, field: 'cogsAmount' | 'breakEvenCpa') => {
    const localValue = localInputs[account.dexieId]?.[field]
    if (localValue === undefined || localValue === account[field]) return

    try {
      await updateAccount({
        ...account,
        [field]: localValue
      })
    } catch (err) {
      setLocalInputs(prev => ({
        ...prev,
        [account.dexieId]: {
          ...prev[account.dexieId],
          [field]: account[field]
        }
      }))
      console.error('Failed to update account:', err)
    }
  }, [localInputs, updateAccount])

  const handleBusinessModeChange = useCallback(async (account: Account, checked: boolean) => {
    const newMode = checked ? 'ecomm' : 'leadgen'
    if (newMode === account.businessMode) return

    setLocalBusinessMode(prev => ({
      ...prev,
      [account.dexieId]: newMode
    }))

    try {
      await updateAccount({
        ...account,
        businessMode: newMode
      })
    } catch (err) {
      setLocalBusinessMode(prev => ({
        ...prev,
        [account.dexieId]: account.businessMode
      }))
      console.error('Failed to update account:', err)
    }
  }, [updateAccount])

  const handleCurrencyChange = useCallback(async (account: Account, value: string) => {
    if (value === account.currency) return

    setLocalCurrency(prev => ({
      ...prev,
      [account.dexieId]: value
    }))

    try {
      await updateAccount({
        ...account,
        currency: value
      })
    } catch (err) {
      setLocalCurrency(prev => ({
        ...prev,
        [account.dexieId]: account.currency
      }))
      console.error('Failed to update account:', err)
    }
  }, [updateAccount])

  // Memoize the debug card
  const debugCard = useMemo(() => DEBUG ? (
    <Card className="mt-8">
      <CardHeader>
        <CardTitle>Debug Info</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Button
            variant="outline"
            onClick={debugDataLoading}
            className="w-full"
          >
            Inspect Dexie DB
          </Button>
          <pre className="text-xs">
            {JSON.stringify(
              {
                accounts: accounts.map(a => ({
                  dexieId: a.dexieId,
                  accountCID: a.accountCID,
                  name: a.name,
                  businessMode: a.businessMode,
                  currency: a.currency,
                  cogsAmount: a.cogsAmount,
                  breakEvenCpa: a.breakEvenCpa,
                  isSampleAccount: a.isSampleAccount,
                  webAppUrl: a.webAppUrl
                })),
                accountSummary: Object.entries(accountData).reduce((acc, [dexieId, data]) => ({
                  ...acc,
                  [dexieId]: {
                    timestamp: data?.timestamp || 'N/A',
                    accountName: data?.[SHEET_TABS.ACCOUNT]?.[0]?.accountName || 'N/A'
                  }
                }), {}),
                loadingStatus,
              },
              null,
              2
            )}
          </pre>
        </div>
      </CardContent>
    </Card>
  ) : null, [accounts, accountData, loadingStatus])

  // Memoize the accounts list to prevent unnecessary re-renders
  const memoizedAccounts = useMemo(() => accounts, [accounts])

  const handleWebAppUrlFetch = useCallback(async (url: string) => {
    try {
      setLoadingDialog({
        title: 'Setting Up Account',
        description: 'Creating account and loading initial data...',
        open: true
      })

      // Create account first
      const response = await fetch(`${url}?tab=account`)
      if (!response.ok) {
        throw new Error(`Failed to fetch account info: ${response.statusText}`)
      }

      const accountData = await response.json()
      const accountInfo = Array.isArray(accountData) ? accountData[0] : accountData

      if (!accountInfo?.accountId && !accountInfo?.accountName) {
        throw new Error('Invalid account data received')
      }

      const accountCID = accountInfo.accountId || accountInfo.accountCID
      const newAccount: Account = {
        id: accountCID,
        dexieId: accountCID,
        accountCID,
        name: accountInfo.accountName,
        webAppUrl: url,
        businessMode: 'ecomm',
        currency: '$',
        cogsAmount: 50,
        breakEvenCpa: 25,
        isSampleAccount: false
      }

      // Add account and get saved version
      const savedAccount = await addAccount(newAccount)

      // Update loading message for data fetch
      setLoadingDialog({
        title: 'Loading Data',
        description: 'Fetching campaign data...',
        open: true
      })

      // Load initial data using campaign data context
      await refreshData(savedAccount.dexieId, url)

      // Update Clerk metadata if signed in
      if (isSignedIn && user) {
        await user.update({
          unsafeMetadata: {
            lastActiveDexieId: savedAccount.dexieId
          }
        })
      }

      toast({
        title: 'Success',
        description: `${newAccount.name} has been connected successfully`
      })

    } catch (err) {
      console.error('Setup error:', err)
      toast({
        variant: 'destructive',
        title: 'Setup Failed',
        description: err instanceof Error ? err.message : 'Failed to set up account'
      })
    } finally {
      setLoadingDialog(null)
    }
  }, [addAccount, isSignedIn, user, toast, refreshData])

  useEffect(() => {
    // Only check for stored URL if user is signed in
    if (!isSignedIn) return

    const storedData = getTempWebAppUrlWithTimestamp()
    if (!storedData) return

    // Only use URLs stored in the last 5 minutes
    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000)
    const storedTime = new Date(storedData.timestamp)

    if (storedTime > fiveMinutesAgo) {
      // URL is fresh enough, try to fetch data
      toast({
        title: "Welcome back!",
        description: "Fetching data from your Web App URL...",
      })

      // Fetch data from the stored URL
      handleWebAppUrlFetch(storedData.url)

      // Clear the stored URL after attempting to use it
      clearTempWebAppUrl()
    } else {
      // URL is too old, just clear it
      clearTempWebAppUrl()
    }
  }, [isSignedIn, handleWebAppUrlFetch, toast])

  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h1 className="text-2xl font-semibold">Accounts</h1>
          <p className="text-sm text-muted-foreground">
            Manage your Google Ads accounts
          </p>
        </div>
        <Button
          onClick={() => navigate('/setup')}
          className="gap-2"
        >
          <Plus className="h-4 w-4" />
          Add Account
        </Button>
      </div>

      <AccountsList
        accounts={memoizedAccounts}
        editingAccount={editingAccount}
        newName={newName}
        accountData={accountData}
        refreshingAccounts={refreshingAccounts}
        localInputs={localInputs}
        localBusinessMode={localBusinessMode}
        localCurrency={localCurrency}
        setNewName={setNewName}
        setEditingAccount={setEditingAccount}
        handleEditName={handleEditName}
        handleRefresh={handleRefresh}
        getAccountCID={getAccountCID}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        onBusinessModeChange={handleBusinessModeChange}
        onCurrencyChange={handleCurrencyChange}
        deleteAccount={deleteAccount}
      />
      {debugCard}

      {/* Loading Dialog */}
      <Dialog open={loadingDialog?.open} onOpenChange={() => { }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{loadingDialog?.title}</DialogTitle>
          </DialogHeader>
          <div className="flex items-center justify-center gap-4 py-8">
            <Loader2 className="h-6 w-6 animate-spin" />
            <p>{loadingDialog?.description}</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
