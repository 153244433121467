// src/lib/sample-data-utils.ts
import Papa from 'papaparse';
import { db } from '@/services/db'
import {
  StorageData,
  DailyData,
  HourlyData,
  CampaignSettings,
  ProductData,
  SearchTermData,
  PMaxData,
  AccountInfo,
  BaseMetrics,
  CampaignMetrics
} from '../types/metrics';
import { SHEET_TABS, SAMPLE_ACCOUNT_ID } from './constants';

const DEBUG = process.env.NODE_ENV === 'development';

interface LocalBaseMetrics {
  impressions: number;
  clicks: number;
  cost: number;
  conversions: number;
  convValue: number;
  imprShare: number;
  lostBudget: number;
  lostRank: number;
}

// Helper to handle both cases for metric names
const getMetricValue = (row: Record<string, any>, camelCase: string, pascalCase: string): number => {
  return Number(row[camelCase] || row[pascalCase] || 0);
};

const transformBaseMetrics = (row: Record<string, any>) => {
  // Get all possible variations
  const impr = getMetricValue(row, 'impr', 'Impressions');
  const clicks = getMetricValue(row, 'clicks', 'Clicks');
  const cost = getMetricValue(row, 'cost', 'Cost');
  const conv = getMetricValue(row, 'conv', 'Conversions');
  const value = getMetricValue(row, 'value', 'ConvValue');
  const imprShare = getMetricValue(row, 'imprShare', 'ImprShare');
  const lostBudget = getMetricValue(row, 'lostBudget', 'LostToBudget');
  const lostRank = getMetricValue(row, 'lostRank', 'LostToRank');

  // Calculate derived metrics
  const CTR = impr ? (clicks / impr) * 100 : 0;
  const CvR = clicks ? (conv / clicks) * 100 : 0;
  const ROAS = cost ? value / cost : 0;
  const CPA = conv ? cost / conv : 0;
  const AOV = conv ? value / conv : 0;
  const profit = value - cost;

  // Return both camelCase and PascalCase versions
  return {
    // camelCase versions
    impr,
    clicks,
    cost,
    conv,
    value,
    imprShare,
    lostBudget,
    lostRank,
    profit,
    CTR,
    CvR,
    ROAS,
    CPA,
    AOV,
    // PascalCase versions
    Impressions: impr,
    Clicks: clicks,
    Cost: cost,
    Conversions: conv,
    ConvValue: value,
    ImprShare: imprShare,
    LostToBudget: lostBudget,
    LostToRank: lostRank
  };
};

const transformCampaignData = (row: Record<string, any>): CampaignMetrics => ({
  ...transformBaseMetrics(row),
  campaign: String(row.campaign || row.Campaign || ''),
  campaignId: String(row.campaignId || row.CampaignId || '')
});

type DataTransformer<T> = (data: Record<string, any>[]) => T[];

const transformers: Record<keyof typeof SHEET_TABS, DataTransformer<any>> = {
  ACCOUNT: (data): AccountInfo[] =>
    data.map(row => ({
      accountName: String(row.accountName || '')
    })),

  DAILY: (data): DailyData[] =>
    data.map(row => ({
      ...transformBaseMetrics(row),
      campaign: String(row.campaign || row.Campaign || ''),
      campaignId: String(row.campaignId || row.CampaignId || ''),
      Date: String(row.date || row.Date || ''),
      Campaign: String(row.campaign || row.Campaign || ''),
      CampaignId: String(row.campaignId || row.CampaignId || ''),
      Impressions: Number(row.impr || row.impressions || row.Impressions || 0),
      Clicks: Number(row.clicks || row.Clicks || 0),
      Cost: Number(row.cost || row.Cost || 0),
      Conversions: Number(row.conv || row.conversions || row.Conversions || 0),
      ConvValue: Number(row.value || row.convValue || row.ConvValue || 0),
      ImprShare: Number(row.ImprShare || row.imprShare || 0),
      LostToBudget: Number(row.LostToBudget || row.lostToBudget || 0),
      LostToRank: Number(row.LostToRank || row.lostRank || 0),
      profit: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0
    })),

  THIRTY_DAYS: (data): DailyData[] => transformers.DAILY(data),
  P_THIRTY_DAYS: (data): DailyData[] => transformers.DAILY(data),
  SEVEN_DAYS: (data): DailyData[] => transformers.DAILY(data),
  P_SEVEN_DAYS: (data): DailyData[] => transformers.DAILY(data),

  HOURLY: (data): HourlyData[] => {
    // Group data by hour and campaign
    const hourlyMap = new Map<string, HourlyData>();

    data.forEach(row => {
      const hour = Number(row.hour || row.Hour || 0);
      const campaign = String(row.campaign || row.Campaign || '');
      const key = `${hour}-${campaign}`;

      const metrics = transformBaseMetrics(row);

      if (!hourlyMap.has(key)) {
        hourlyMap.set(key, {
          hour,
          Hour: hour,
          campaign,
          Campaign: campaign,
          campaignId: String(row.campaignId || row.CampaignId || ''),
          CampaignId: String(row.campaignId || row.CampaignId || ''),
          ...metrics
        });
      } else {
        // Aggregate metrics for same hour and campaign
        const existing = hourlyMap.get(key)!;
        existing.impr += metrics.impr;
        existing.Impressions += metrics.Impressions;
        existing.clicks += metrics.clicks;
        existing.Clicks += metrics.Clicks;
        existing.cost += metrics.cost;
        existing.Cost += metrics.Cost;
        existing.conv += metrics.conv;
        existing.Conversions += metrics.Conversions;
        existing.value += metrics.value;
        existing.ConvValue += metrics.ConvValue;
      }
    });

    return Array.from(hourlyMap.values());
  },

  HOURLY_YEST: (data): HourlyData[] => transformers.HOURLY(data),

  SETTINGS: (data): CampaignSettings[] =>
    data.map(row => ({
      campaign: String(row.Campaign || row.campaign || ''),
      bidStrategy: String(row.BidStrategy || ''),
      budget: Number(row.Budget || 0),
      status: String(row.Status || ''),
      labels: String(row.Labels || ''),
      maxCpc: Number(row.MaxCPC || 0),
      rtbOptIn: Boolean(row.RTBOptIn),
      statusReasons: String(row.StatusReasons || '')
    })),

  PRODUCTS: (data): ProductData[] =>
    data.map(row => {
      const metrics = transformBaseMetrics(row);
      return {
        impressions: metrics.impr,
        clicks: metrics.clicks,
        cost: metrics.cost,
        conversions: metrics.conv,
        convValue: metrics.value,
        imprShare: metrics.imprShare,
        lostBudget: metrics.lostBudget,
        lostRank: metrics.lostRank,
        ProductId: String(row.ProductId || ''),
        ProductTitle: String(row.ProductTitle || '')
      };
    }),

  SEARCH_TERMS: (data): SearchTermData[] =>
    data.map(row => {
      const metrics = transformBaseMetrics(row);
      return {
        impressions: metrics.impr,
        clicks: metrics.clicks,
        cost: metrics.cost,
        conversions: metrics.conv,
        convValue: metrics.value,
        imprShare: metrics.imprShare,
        lostBudget: metrics.lostBudget,
        lostRank: metrics.lostRank,
        SearchTerm: String(row.SearchTerm || '')
      };
    }),

  PMAX_PERF: (data): PMaxData[] =>
    data.map(row => ({
      Date: String(row.Date || row.date || ''),
      Campaign: String(row.Campaign || row.campaign || ''),
      "Search Cost": Number(row["Search Cost"] || row.SearchCost || 0),
      "Search Conv": Number(row["Search Conv"] || row.SearchConv || 0),
      "Search Value": Number(row["Search Value"] || row.SearchValue || 0),
      "Display Cost": Number(row["Display Cost"] || row.DisplayCost || 0),
      "Display Conv": Number(row["Display Conv"] || row.DisplayConv || 0),
      "Display Value": Number(row["Display Value"] || row.DisplayValue || 0),
      "Video Cost": Number(row["Video Cost"] || row.VideoCost || 0),
      "Video Conv": Number(row["Video Conv"] || row.VideoConv || 0),
      "Video Value": Number(row["Video Value"] || row.VideoValue || 0),
      "Shopping Cost": Number(row["Shopping Cost"] || row.ShoppingCost || 0),
      "Shopping Conv": Number(row["Shopping Conv"] || row.ShoppingConv || 0),
      "Shopping Value": Number(row["Shopping Value"] || row.ShoppingValue || 0),
      "Total Cost": Number(row["Total Cost"] || row.TotalCost || 0),
      "Total Conv": Number(row["Total Conv"] || row.TotalConv || 0),
      "Total Value": Number(row["Total Value"] || row.TotalValue || 0)
    }))
};

const createEmptyStorageData = (): Omit<StorageData, 'timestamp'> => ({
  dexieId: SAMPLE_ACCOUNT_ID,
  accountCID: SAMPLE_ACCOUNT_ID,
  [SHEET_TABS.ACCOUNT]: [],
  [SHEET_TABS.DAILY]: [],
  [SHEET_TABS.HOURLY]: [],
  [SHEET_TABS.HOURLY_YEST]: [],
  [SHEET_TABS.THIRTY_DAYS]: [],
  [SHEET_TABS.P_THIRTY_DAYS]: [],
  [SHEET_TABS.SEVEN_DAYS]: [],
  [SHEET_TABS.P_SEVEN_DAYS]: [],
  [SHEET_TABS.SETTINGS]: [],
  [SHEET_TABS.PRODUCTS]: [],
  [SHEET_TABS.SEARCH_TERMS]: [],
  [SHEET_TABS.PMAX_PERF]: []
});

// src/lib/sample-data-utils.ts - Update the return structure
export async function loadSampleData(): Promise<StorageData> {
  console.log('Starting sample data load...');

  const timestamp = new Date().toISOString();
  const emptyData = createEmptyStorageData();

  try {
    const results = await Promise.all(
      (Object.keys(SHEET_TABS) as Array<keyof typeof SHEET_TABS>).map(async (tab) => {
        try {
          console.log(`Loading ${tab} data...`);
          const rawData = await loadCsvFile(tab);
          return {
            tab,
            data: transformers[tab](rawData)
          };
        } catch (err) {
          console.warn(`Failed to load ${tab} data:`, err);
          return {
            tab,
            data: []
          };
        }
      })
    );

    const result: StorageData = {
      ...emptyData,
      timestamp,
      dexieId: SAMPLE_ACCOUNT_ID
    };

    // Populate data from results
    results.forEach(({ tab, data }) => {
      const key = SHEET_TABS[tab];
      result[key] = data;
    });

    console.log('Sample data load complete');
    return result;
  } catch (err) {
    console.error('Failed to load sample data:', err);
    // Return empty data structure instead of throwing
    return {
      ...emptyData,
      timestamp,
      dexieId: SAMPLE_ACCOUNT_ID
    };
  }
}

async function loadCsvFile(tab: keyof typeof SHEET_TABS): Promise<Record<string, any>[]> {
  try {
    if (DEBUG) console.log(`Loading CSV for ${tab}...`);

    const response = await fetch(`/sample-data/sample_${SHEET_TABS[tab].toLowerCase()}.csv`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();

    return new Promise((resolve, reject) => {
      Papa.parse(text, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length > 0) {
            console.warn(`Parse warnings for ${tab}:`, results.errors);
          }
          resolve(results.data);
        },
        error: (error) => reject(error)
      });
    });
  } catch (err) {
    console.error(`Error loading ${tab}:`, err);
    return [];
  }
}

export async function loadSampleDataInBackground(): Promise<void> {
  try {
    // Wait for initial delay
    await new Promise(resolve => setTimeout(resolve, 1500))

    // Load sample data
    const sampleData = await loadSampleData()

    // Store in DB
    await db.saveAccountData(SAMPLE_ACCOUNT_ID, sampleData)

    console.log('Background sample data load complete')
  } catch (err) {
    console.error('Background sample data load failed:', err)
  }
}